import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import { Button, Popup, Header, Divider, Input as InputSemantic, Label, Icon } from "semantic-ui-react";
import { Collapse } from "react-collapse";
import "./precioDormis.css";
import { HabitacionesDisponiblesXFecha, chooseCurrency } from "../../../Funciones/utils";
import ChangeTypeSelect from "../extras/changeTypeSelect";

export const ActualizarDisponibilidada = async (fecha, roomId, setListaDisponibilidad) => {
  let primerDia = moment(fecha);
  let ultimoDia = moment(fecha).add(30, "days");
  let lista = [];
  const disponibilidad = {}; //await AgarrarDisponibilidad(primerDia,ultimoDia)
  const dias = ultimoDia.diff(primerDia, "days");
  for (let index = 0; index < dias; index++) {
    const fechaFormateada = primerDia.format("YYYY-MM-DD");
    const obj = {
      fecha: fechaFormateada,
      disponibilidad: disponibilidad[roomId][fechaFormateada],
    };
    lista = [...lista, obj];
    primerDia.add(1, "days");
  }
  setListaDisponibilidad(lista);
};

const ChangePricesPopup = ({
  input,
  fechaEntrada,
  fechaSalida,
  nombreChannel,
  nombrePrecio,
  precioPopup,
  setPrecioPopup,
  setOpenPopup,
  tipoPrecio,
  isDispo,
  realAvailability,
  isRepublicar,
  isEstadiaMinima,
  isEstadiaMaxima,
  setStartDrag,
  startDrag,
}) => {
  const dispatch = useDispatch();
  const enviarPrecios = () => {
    if (isRepublicar || isDispo || precioPopup >= 0) {
      const actionType = (isRepublicar || isEstadiaMinima || isEstadiaMaxima)
        ? "MODIFY_AUTOUPDATE"
        : isDispo
        ? "MODIFY_AVAILABILITY"
        : "MODIFY_PRICES";
      if(actionType=='MODIFY_AVAILABILITY' && realAvailability < precioPopup){
        alert("Error: No puede haber más lugares libres que lugares en venta");
        return;
      }
      dispatch({
        type: actionType,
        payload: {
          tipoPrecio,
          from: fechaEntrada,
          to: fechaSalida,
          item: nombrePrecio,
          price: precioPopup,
          availability: precioPopup,
          autoUpdateAvailability: isRepublicar ? precioPopup : null,
          minStay: isEstadiaMinima ? precioPopup : null,
          maxStay: isEstadiaMaxima ? precioPopup : null,
        },
      });
    } else {
      alert("Falta completar el campo");
    }
  };
  
  const cerrarPopup = () => {
    setOpenPopup(false);
  };
  
  const handleInputChange = (e, { value }, field) => {
    setStartDrag({ ...startDrag, [field]: value });
  };
  
  const handlePrecioPopupChange = (e) => {
    setPrecioPopup(e.target.value);
  };
  
  const handleKeyPress = (target) => {
    if (target.charCode === 13) {
      enviarPrecios();
    }
  };
  
  const precioLabel = isEstadiaMaxima
    ? "Estadia máxima"
    : isEstadiaMinima
    ? "Estadia mínima"
    : isRepublicar
    ? "Republicar automatico"
    : isDispo
    ? "En venta"
    : "Precio";
  
  return (
    <Fragment>
      <div className="d-flex flex-column bd-highlight">
        <div className="d-flex justify-content-between">
          <div
            className="p-2 bd-highlight"
            style={{ cursor: "pointer" }}
            onClick={cerrarPopup}
          >
            <Header size="large">{nombreChannel}</Header>
          </div>
          <div
            className="p-2 bd-highlight"
            style={{ cursor: "pointer" }}
            onClick={cerrarPopup}
          >
            <Icon name="close" size="large" color="black" />
          </div>
        </div>
        <br />
        <div className="d-flex flex-column pl-2 pr-2 bd-highlight">
          <InputSemantic
            label="Desde"
            style={{ marginBottom: "5px" }}
            type="date"
            value={fechaEntrada}
            onChange={(e, { value }) => handleInputChange(e, { value }, "fechaInicio")}
          />
          <InputSemantic
            label="Hasta"
            type="date"
            value={fechaSalida}
            onChange={(e, { value }) => handleInputChange(e, { value }, "fechaFin")}
          />
        </div>
        <Divider />
        <div className="py-2 bd-highlight">
          <div className="d-flex flex-row bd-highlight mb-3 align-items-center">
            <div className={"p-2 display-5"} style={{ width: "150px" }}>
              {precioLabel} <strong>{nombrePrecio}</strong>
            </div>
            <div className={"p-2"}>
              <InputSemantic labelPosition="left" type="number" placeholder="">
                {precioLabel == "Precio" && <Label basic>$</Label>}
                <input
                  onKeyPress={handleKeyPress}
                  ref={input}
                  style={{ width: "100px" }}
                  value={precioPopup}
                  onChange={handlePrecioPopupChange}
                />
              </InputSemantic>
            </div>
          </div>
        </div>
        <div className="pl-2 pt-2 bd-highlight">
          <div className="d-flex flex-row bd-highlight mb-3 align-items-center">
            <div className={"px-2 col-md-6"}>
              <Button color="teal" fluid onClick={enviarPrecios}>
                Guardar
              </Button>
            </div>
            <div className={"px-2 col-md-6"}>
              <Button
                fluid
                onClick={() => {
                  cerrarPopup();
                  setStartDrag({});
                }}
              >
                Cerrar
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const Input = ({precio, fecha, currency, nombrePrecio, startDrag, setStartDrag, setOpenPopup}) => {
  const onMouseOver = () => {
    if (startDrag.start && startDrag.fechaFin !== fecha) {
      const isStartDateAfter = moment(startDrag.fechaInicio).diff(moment(fecha), 'days') >= 0;

      setStartDrag({
        ...startDrag,
        [isStartDateAfter ? 'fechaInicio' : 'fechaFin']: fecha,
      });
    }
  };

  const onMouseUp = () => {
    setOpenPopup(true);

    if (startDrag.fechaInicio) {
      setStartDrag({ ...startDrag, start: false });
    }
  };

  const onMouseDown = () => {
    setStartDrag({
      nombrePrecio,
      fechaInicio: fecha,
      fechaFin: fecha,
      start: true,
    });
  };

  const isSelected = startDrag.nombrePrecio === nombrePrecio &&
    moment(fecha).isBetween(startDrag.fechaInicio, startDrag.fechaFin, 'day', '[]');

  const isWeekend = moment(fecha).isoWeekday() === 6 || moment(fecha).isoWeekday() === 7;

  return (
    <div
      onMouseOver={onMouseOver}
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
      className={`Noselect rna-grid__cell rna-value-wrapper js-rna-cell js-drag-and-select rna-grid__cell--actionable ${isSelected ? 'Select' : ''}`}
      data-date="2019-11-06"
      style={{
        backgroundColor: isWeekend ? 'rgb(255, 248, 183)' : '#fff',
      }}
    >
      <span className="rna-value rna-value--bottom rna-value--caption">
        <div className="rna-value--currency">{chooseCurrency(currency)}</div>
        {precio}
      </span>
    </div>
  );
};

export const MesYAno = props => {
  return (
    <React.Fragment>
      <div
        className="rna-title--headline"
        style={{ borderRight: "0px", padding: "0.5rem" }}
      >
        {props.texto}
      </div>
    </React.Fragment>
  )
}

export const Dia = ({
  texto,
  style,
  isWeekend,
  startDrag,
  setStartDrag,
  fecha,
  setOpenPopup,
  isDispo,
  isRepublicar,
  isEstadiaMinima,
  isEstadiaMaxima,
}) => {
  const handleMouseOver = () => {
    if (
      (isDispo || isRepublicar || isEstadiaMinima || isEstadiaMaxima) &&
      startDrag?.start &&
      startDrag?.fechaFin !== fecha
    ) {
      setStartDrag({ ...startDrag, fechaFin: fecha });
    }
  };

  const handleMouseUp = () => {
    if (isDispo || isRepublicar || isEstadiaMinima || isEstadiaMaxima) {
      setOpenPopup(true);
    }
    if (startDrag?.fechaInicio) {
      setStartDrag({ ...startDrag, fechaFin: fecha, start: false });
    }
  };

  const handleMouseDown = () => {
    if (isDispo || isRepublicar || isEstadiaMinima || isEstadiaMaxima) {
      setStartDrag({
        isDispo,
        isRepublicar,
        isEstadiaMinima,
        isEstadiaMaxima,
        fechaInicio: fecha,
        start: true,
      });
    }
  };

  const isSelected = ((startDrag?.isRepublicar && isRepublicar) ||
    (startDrag?.isEstadiaMinima && isEstadiaMinima) ||
    (startDrag?.isEstadiaMaxima && isEstadiaMaxima) ||
    (startDrag?.isDispo && isDispo)) &&
    moment(fecha).isBetween(
      startDrag.fechaInicio,
      startDrag.fechaFin,
      "day",
      "[]"
    );

  const cellClassName = `Noselect rna-grid__cell rna-value-wrapper js-drag-and-select js-rna-cell rna-grid__cell--actionable ${
    isSelected ? "Select" : ""
  }`;

  return (
    <div
      onMouseOver={handleMouseOver}
      onMouseUp={handleMouseUp}
      onMouseDown={handleMouseDown}
      className={cellClassName}
      data-date="2019-10-07"
      style={{ backgroundColor: isWeekend ? "rgb(255, 248, 183)" : "#fff" }}
    >
      <span style={style} className="rna-value rna-value--bottom">
        {texto}
      </span>
    </div>
  );
};

const Disponibilidad = ({ disponibilidad, fecha, isWeekend, tipoPrecio }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch({
      type: "MODIFY_AVAILABILITY",
      payload: {
        tipoPrecio,
        from: fecha,
        to: fecha,
        availability: disponibilidad === 0 ? 1 : 0,
      },
    });
  };

  return (
    <div
      className="rna-grid__cell js-rna-cell rna-value-wrapper"
      data-date="2019-10-07"
      style={{ backgroundColor: isWeekend ? "rgb(255, 248, 183)" : "#fff" }}
    >
      <span
        id="rna-55835201-2019-10-07-label"
        onClick={handleClick}
        style={!disponibilidad ? { backgroundColor: "#e8bebe" } : {}}
        className={`rna-label rna-label--primary  rna-label--positive rna-label--actionable ${disponibilidad}`}
        data-on-click="toggleRoomStatus('55835201', '2019-10-07', event)"
      >
        &nbsp;
      </span>
    </div>
  );
};

const Buscador = ({ setFechaInicio, setFechaFin }) => {
  const listaMeses = Array.from({ length: 12 }, (_, index) =>
    moment()
      .startOf("month")
      .add(index, "month")
  );

  const actualizarFechas = (setFechaInicio, setFechaFin, fechaElegida) => {
    if (moment(fechaElegida).format("MM") === moment().format("MM")) {
      setFechaInicio(moment().format("YYYY-MM-DD"));
      setFechaFin(
        moment()
          .add(31, "days")
          .format("YYYY-MM-DD")
      );
    } else {
      setFechaInicio(
        moment(fechaElegida)
          .startOf("month")
          .format("YYYY-MM-DD")
      );
      setFechaFin(
        moment(fechaElegida)
          .endOf("month")
          .format("YYYY-MM-DD")
      );
    }
  };

  const handleChange = (event) => {
    actualizarFechas(setFechaInicio, setFechaFin, event.target.value);
  };

  return (
    <div className="row">
      <div className="col-md-3">
        <h4 style={{ textAlign: "left", marginBottom: "5px" }}>Mes</h4>
        <select
          className="form-control"
          id="exampleFormControlSelect1"
          onChange={handleChange}
        >
          {listaMeses.map((x, i) => (
            <option value={x.format("YYYY-MM-DD")} key={i}>
              {x.format("MMMM YYYY")}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const Main = () => {
  const preciosXFecha = useSelector(state => state.mainReducer.preciosXFecha)
  const preciosXFechaOta = useSelector(state => state.mainReducer.preciosXFechaOta)
  const defaultCurrency = useSelector(state => state.mainReducer.defaultCurrency)
  const listaReservas = useSelector(state => state.mainReducer.listaReservas)
  const tipoPrecio = useSelector(state => state.mainReducer.tipoPrecio)
  const loading = useSelector(state => state.mainReducer.priceLoading)

  return (
    <LoadingOverlay active={loading} spinner text="Actualizando">
      <Calendario
        listaReservas={listaReservas}
        currency={defaultCurrency || "ARS"}
        precioElegido={preciosXFecha.find(x => x.type == tipoPrecio)}
        nombreChannel={`Mostrador`}
        tipoPrecio={tipoPrecio}
      />
      {preciosXFechaOta
        .filter(x => x.type.split("-")[0] == tipoPrecio)
        .map((x,i) => (
          <Fragment key={i}>
            <br></br>
            <Calendario
              currency={x.name=="Hostelworld"?"ARS":"USD"}
              precioElegido={x}
              nombreChannel={x.name}
              listaReservas={listaReservas}
              tipoPrecio={tipoPrecio}
            />
          </Fragment>
        ))}
    </LoadingOverlay>
  )
}

//----------------------------------------------------------------------------------------------
const Calendario = ({ precioElegido, currency, nombreChannel, listaReservas, tipoPrecio }) => {  
  const [listaDisponibilidad, setListaDisponibilidad] = useState([]);
  const [listaDisponibilidadReal, setListaDisponibilidadReal] = useState([]);
  const [listasExtras, setListasExtras] = useState({
    listaAutoAjustar: [],
    listaEstadiaMinima: [],
    listaEstadiaMaxima: [],
  });
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [isOnline, setIsOnline] = useState(false);
  const [listaDeListaDePrecios, setListaDeListaDePrecios] = useState([]);
  const [precioPopup, setPrecioPopup] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const [startDrag, setStartDrag] = useState({});
  const [collapseItems, setCollapseItems] = useState(false);
  const input = useRef();

  useEffect(() => {
    setFechaInicio(moment().format("YYYY-MM-DD"));
    setFechaFin(
      moment()
        .add(31, "days")
        .format("YYYY-MM-DD")
    );
  }, []);

  useEffect(() => {
    if (input.current) {
      input.current.focus();
      setPrecioPopup("");
    }
  }, [openPopup]);

  useEffect(() => {
    setListaDisponibilidadReal(
      HabitacionesDisponiblesXFecha(fechaInicio, fechaFin)
    );
  }, [fechaInicio, fechaFin, listaReservas, tipoPrecio]);

  useEffect(() => {
    if (precioElegido) {
      const precioTipo = precioElegido;
      setListaDisponibilidad(
        precioTipo.disponibilidad.filter(x =>
          moment(x.fecha).isBetween(fechaInicio, fechaFin, null, "[]")
        )
      );
      setListasExtras({
        listaAutoAjustar: precioTipo.autoAjustar?.filter(x =>
          moment(x.fecha).isBetween(fechaInicio, fechaFin, null, "[]")
        ) ?? [],
        listaEstadiaMinima: precioTipo.estadiaMinima?.filter(x =>
          moment(x.fecha).isBetween(fechaInicio, fechaFin, null, "[]")
        ) ?? [],
        listaEstadiaMaxima: precioTipo.estadiaMaxima?.filter(x =>
          moment(x.fecha).isBetween(fechaInicio, fechaFin, null, "[]")
        ) ?? [],
      });
      const p = precioTipo.precios.map(x => ({
        ...x,
        list: x.list.filter(y =>
          moment(y.fecha).isBetween(fechaInicio, fechaFin, null, "[]")
        ),
      }));
      setListaDeListaDePrecios(p);
      setIsOnline(precioTipo.isOnline);
    }
  }, [precioElegido, fechaInicio, fechaFin]);

  if (!precioElegido) {
    return <></>;
  }

  return (
    <>
      <div className="bordeado">
        <ChangeTypeSelect/>
        <Buscador setFechaInicio={setFechaInicio} setFechaFin={setFechaFin} />
        <div
          className="rna-grid js-scroll-sync "
          id="rna-grid"
          data-render-id="rna-matrix"
        >
          <Header size={"large"}>{nombreChannel}</Header>
          <div
            className="rna-grid__row "
            id="net-booked-55835201"
            data-render-id="rna-room-block"
            style={{ border: "0px" }}
          >
            <div
              className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left rna-title"
              style={{ border: "0px" }}
            ></div>
            {}
            {listaDeListaDePrecios[0]
              ? listaDeListaDePrecios[0].list.map((x, i) => (
                  <MesYAno
                    texto={
                      i == 0
                        ? moment(x.fecha)
                            .format("MMMM")
                            .toUpperCase()
                        : i == 1
                        ? moment(x.fecha).format("YYYY")
                        : ""
                    }
                    key={i}
                  />
                ))
              : ""}
          </div>
          <div
            className="rna-grid__row js-rna-bulk-form-row js-onboarding-rooms"
            data-render-id="rna-room-block"
            data-form-type="rooms_to_sell"
            id="rooms-to-sell-55835201"
          >
            <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left">
              <div className="rna-title">Dia</div>
            </div>
            {listaDeListaDePrecios[0]
              ? listaDeListaDePrecios[0].list.map((x, i) => (
                  <Dia
                    isWeekend={
                      moment(x.fecha).isoWeekday() === 6 ||
                      moment(x.fecha).isoWeekday() === 7
                    }
                    texto={moment(x.fecha).format("dd D")}
                    key={i}
                  />
                ))
              : ""}
          </div>

          {isOnline && (
            <div
              className="rna-grid__row js-rna-bulk-form-row js-onboarding-room-status "
              data-render-id="rna-room-block"
              data-form-type="room_status"
              id="room-status-55835201"
            >
              <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left rna-title">
                Estado
              </div>
              {listaDisponibilidad.length > 0
                ? listaDisponibilidad.map((x, i) => (
                    <Disponibilidad
                      tipoPrecio={precioElegido.type}
                      isWeekend={
                        moment(x.fecha).isoWeekday() === 6 ||
                        moment(x.fecha).isoWeekday() === 7
                      }
                      disponibilidad={x.disponibilidad}
                      fecha={x.fecha}
                      key={i}
                    />
                  ))
                : ""}
            </div>
          )}
          {
            <div
              className="rna-grid__row js-rna-bulk-form-row js-onboarding-room-status "
              data-render-id="rna-room-block"
              data-form-type="room_status"
              id="room-status-55835201"
            >
              <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left">
                <div className="rna-title">Libres</div>
              </div>

              {listaDisponibilidadReal.length > 0
                ? listaDisponibilidadReal.map((x, i) => (
                    <Dia
                      fecha={x.fecha}
                      startDrag={startDrag}
                      setStartDrag={setStartDrag}
                      setOpenPopup={setOpenPopup}
                      isDispo={false}
                      isRepublicar={false}
                      isEstadiaMaxima={false}
                      isEstadiaMinima={false}
                      style={{
                        textAlign: "center",
                        fontSize: 16,
                        fontWeight: 700,
                      }}
                      isWeekend={
                        moment(x.fecha).isoWeekday() === 6 ||
                        moment(x.fecha).isoWeekday() === 7
                      }
                      texto={x.disponibilidad}
                      key={i}
                    />
                  ))
                : ""}
            </div>
          }

          {isOnline && (
            <div
              className="rna-grid__row js-rna-bulk-form-row js-onboarding-room-status "
              data-render-id="rna-room-block"
              data-form-type="room_status"
              id="room-status-55835201"
            >
              <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left">
                <div className="rna-title">
                  En venta
                  {listasExtras.listaAutoAjustar.length > 0&& <span
                    className="rna-title--expandable-icon"
                    onClick={() => setCollapseItems(!collapseItems)}
                  >
                    {collapseItems ? (
                      <svg
                        width="16"
                        height="16"
                        color="blue"
                        viewBox="0 0 128 128"
                        role="presentation"
                        aria-hidden="true"
                        focusable="false"
                        className="av-cal-list-row-header-link__icon bk-icon -iconset-navarrow_up"
                      >
                        <path d="M92 77.7a4 4 0 0 1-2.8-1.2L64 51.3 38.8 76.5a4 4 0 0 1-5.6-5.7L64 40l30.8 30.8a4 4 0 0 1-2.8 6.9z"></path>
                      </svg>
                    ) : (
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 128 128"
                        role="presentation"
                        aria-hidden="true"
                        focusable="false"
                        className="av-cal-list-row-header-link__icon bk-icon -iconset-navarrow_down"
                      >
                        <path d="M64 88L33.2 57.2a4 4 0 0 1 5.6-5.7L64 76.7l25.2-25.2a4 4 0 0 1 5.6 5.7z"></path>
                      </svg>
                    )}
                  </span>}
                </div>
              </div>
              {listaDisponibilidad.length > 0
                ? listaDisponibilidad.map((x, i) => (
                    <Dia
                      fecha={x.fecha}
                      dispoReal={listaDisponibilidadReal.find(
                        y => y.fecha === x.fecha
                      )||0}
                      startDrag={startDrag}
                      setStartDrag={setStartDrag}
                      setOpenPopup={setOpenPopup}
                      isDispo={true}
                      isRepublicar={false}
                      isEstadiaMinima={false}
                      isEstadiaMaxima={false}
                      style={{
                        textAlign: "center",
                        fontSize: 16,
                        fontWeight: 700,
                      }}
                      isWeekend={
                        moment(x.fecha).isoWeekday() === 6 ||
                        moment(x.fecha).isoWeekday() === 7
                      }
                      texto={x.disponibilidad}
                      key={i}
                    />
                  ))
                : ""}
            </div>
          )}
          {isOnline&&<Collapse isOpened={collapseItems}>

              <div
                className="rna-grid__row js-rna-bulk-form-row js-onboarding-room-status "
                data-render-id="rna-room-block"
                data-form-type="room_status"
                id="room-status-55835201"
              >
                <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left extraItem">
                  <div className="rna-title">Duración min. estancia</div>
                </div>
                {listasExtras.listaEstadiaMinima.length > 0
                  ? listasExtras.listaEstadiaMinima.map((x, i) => (
                      <Dia
                        fecha={x.fecha}
                        startDrag={startDrag}
                        setStartDrag={setStartDrag}
                        setOpenPopup={setOpenPopup}
                        isDispo={false}
                        isRepublicar={false}
                        isEstadiaMinima={true}
                        isEstadiaMaxima={false}
                        style={{
                          textAlign: "center",
                          fontSize: 16,
                        }}
                        isWeekend={
                          moment(x.fecha).isoWeekday() === 6 ||
                          moment(x.fecha).isoWeekday() === 7
                        }
                        texto={x.estadiaMinima}
                        key={i}
                      />
                    ))
                  : ""}
              </div>

              <div
                className="rna-grid__row js-rna-bulk-form-row js-onboarding-room-status "
                data-render-id="rna-room-block"
                data-form-type="room_status"
                id="room-status-55835201"
              >
                <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left extraItem">
                  <div className="rna-title">Duración max. estancia</div>
                </div>
                {listasExtras.listaEstadiaMaxima.length > 0
                  ? listasExtras.listaEstadiaMaxima.map((x, i) => (
                      <Dia
                        fecha={x.fecha}
                        startDrag={startDrag}
                        setStartDrag={setStartDrag}
                        setOpenPopup={setOpenPopup}
                        isDispo={false}
                        isRepublicar={false}
                        isEstadiaMaxima={true}
                        isEstadiaMinima={false}
                        style={{
                          textAlign: "center",
                          fontSize: 16,
                        }}
                        isWeekend={
                          moment(x.fecha).isoWeekday() === 6 ||
                          moment(x.fecha).isoWeekday() === 7
                        }
                        texto={x.estadiaMaxima}
                        key={i}
                      />
                    ))
                  : ""}
              </div>
              {listasExtras.listaAutoAjustar.length > 0 && (
              <div
                className="rna-grid__row js-rna-bulk-form-row js-onboarding-room-status "
                data-render-id="rna-room-block"
                data-form-type="room_status"
                id="room-status-55835201"
              >
                <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left extraItem">
                  <div className="rna-title">Republicar</div>
                </div>
                {listasExtras.listaAutoAjustar.length > 0
                  ? listasExtras.listaAutoAjustar.map((x, i) => (
                      <Dia
                        fecha={x.fecha}
                        startDrag={startDrag}
                        setStartDrag={setStartDrag}
                        setOpenPopup={setOpenPopup}
                        isDispo={false}
                        isRepublicar={true}
                        isEstadiaMaxima={false}
                        isEstadiaMinima={false}
                        style={{
                          textAlign: "center",
                          fontSize: 16,
                        }}
                        isWeekend={
                          moment(x.fecha).isoWeekday() === 6 ||
                          moment(x.fecha).isoWeekday() === 7
                        }
                        texto={x.autoAjustar}
                        key={i}
                      />
                    ))
                  : ""}
              </div>
            )}
          </Collapse>}
          {listaDeListaDePrecios.map((x, i) => (
            <React.Fragment key={i}>
              <div
                className="rna-grid__row js-onboarding-prices js-rna-bulk-form-row js-observe-visibility"
                data-render-id="rna-room-block"
                data-rate-id={1799663}
                data-occupancy={2}
                id="rate-status-55835201-1799663"
                data-form-type="rates"
              >
                <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left">
                  <span 
                    className="rna-titlerna-title--expandable bui-link bui-link--primary"
                    title="ID de tarifa: 1799663"
                    data-on-click="toggleRateCategory('55835201','1799663',null,0,0)"
                  >
                    {x.name}
                  </span>

                  <span className="rna-title rna-title--secondary"></span>
                </div>
                {x.list
                  ? x.list.map((y, i) => (
                      <Input
                        setOpenPopup={setOpenPopup}
                        setStartDrag={setStartDrag}
                        startDrag={startDrag}
                        precioPopup={precioPopup}
                        nombrePrecio={x.name}
                        setPrecioPopup={setPrecioPopup}
                        nombreChannel="Booking.com"
                        currency={currency}
                        key={i}
                        precio={y.precio}
                        fecha={y.fecha}
                        roomId={y.roomtype_id}
                      />
                    ))
                  : ""}
              </div>
            </React.Fragment>
          ))}
          <Popup
            trigger={<div></div>}
            content={ChangePricesPopup({
              input,
              fechaEntrada: startDrag.fechaInicio,
              fechaSalida: startDrag.fechaFin,
              realAvailability: Math.min(...listaDisponibilidadReal.filter(x =>
                moment(x.fecha).isBetween(
                  startDrag.fechaInicio,
                  startDrag.fechaFin,
                  null,
                  "[]" )).map(x => x.disponibilidad)),
              nombreChannel,
              isEstadiaMinima:startDrag.isEstadiaMinima,
              isEstadiaMaxima:startDrag.isEstadiaMaxima,
              nombrePrecio: startDrag.nombrePrecio
                ? startDrag.nombrePrecio
                : "",
              precioPopup,
              setPrecioPopup,
              setOpenPopup,
              tipoPrecio: precioElegido.type,
              isDispo: startDrag.isDispo,
              isRepublicar: startDrag.isRepublicar,
              setStartDrag,
              startDrag,
            })}
            open={openPopup}
            position="bottom right"
            offset={"0,1000px"}
          />
        </div>
      </div>
    </>
  )
}

export default Main
