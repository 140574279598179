import React,{useState,useEffect,useRef} from 'react'
import ReactTable from 'react-table-6'
import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
import {CapitalFirst} from '../../../../Funciones/utils'
import PagosUsuarios from './graficos'
import {Table,Label, Icon} from 'semantic-ui-react'

const withCaja=(componente,dispatch,reservation)=><div className="test-WithCaja" style={{cursor:"pointer",textDecoration:reservation.canceled?'line-through':""}} onClick={()=>{console.log(reservation);dispatch({type:'OPEN_RESERVATION',payload:reservation.reservationId});}}>{componente}</div>

const TablaListaPagos=()=>{
  const dispatch=useDispatch();
  const isMobile=useSelector(x=>x.mainReducer.isMobile)
  const paymentListStatistics=useSelector(x=>x.mainReducer.paymentListStatistics)
  var paymentColumn = [
    {
    Header: isMobile?`Creado`:`Fecha Creado`,
    accessor: 'date',
    style:{fontSize:"1.1rem"},
    headerStyle:{fontSize:"1.2rem"},
    Cell: props => withCaja(moment(props.value).format(isMobile?'DD/MM':'DD/MM/YYYY - HH:mm'),dispatch,props.original),
    filterable: false
      },

    {
    Header: `Descripcion`,
    accessor: 'description',
    style:{fontSize:"1.1rem"},
    headerStyle:{fontSize:"1.2rem"},
    
    Cell: props => withCaja(props.value,dispatch,props.original),
    filterable: false
      },
    {
    Header: `Monto`,
    accessor: 'amount',
    style:{fontSize:"1.1rem"},
    headerStyle:{fontSize:"1.2rem"},
    Cell: props => withCaja(`$ ${props.value}`,dispatch,props.original),
    filterable: false
      },
    {
    Header: 'Forma',
    accessor: 'method',
    style:{fontSize:"1.1rem"},
    headerStyle:{fontSize:"1.2rem"},
    Cell: props => withCaja(`${props.value}`,dispatch,props.original),
    filterable: false
      },
    {
    Header: 'De',
    accessor: 'nombreyape',
    style:{fontSize:"1.1rem"},
    headerStyle:{fontSize:"1.2rem"},
    Cell: props => withCaja(`${props.value??'-'}`,dispatch,props.original),
    filterable: false
      },
    {
    Header: 'Lugares',
    accessor: 'lugares',
    style:{fontSize:"1.1rem"},
    headerStyle:{fontSize:"1.2rem"},
    Cell: props => withCaja(`${props.value??'-'}`,dispatch,props.original),
    filterable: false
      },
    {
    Header: 'Facturado',
    accessor: 'isBilled',
    style:{fontSize:"1.1rem"},
    headerStyle:{fontSize:"1.2rem"},
    Cell: props => withCaja(props.value?<Icon color='green' name='check'/>:<Icon name='times'/>,dispatch,props.original),
    filterable: false
      },
    {
    Header: 'Usuario',
    accessor: 'specificUsername',
    style:{fontSize:"1.1rem"},
    headerStyle:{fontSize:"1.2rem"},
    Cell: props => withCaja(CapitalFirst(props.value),dispatch,props.original),
    filterable: false
      },
    ]
    
    return(
    <React.Fragment>
      <ReactTable         
        rowsText={"Filas"}
        nextText={"Siguiente"}
        pageText={"Página"}
        ofText={"de"}
        previousText={"Anterior"}
        noDataText={'No se encontraron pagos para la fecha'} className="-striped -highlight" 
        filterable loadingText={"Cargando..."} loading={false} columns={paymentColumn} data={paymentListStatistics}></ReactTable>
    </React.Fragment>
    )
  }

export default TablaListaPagos

