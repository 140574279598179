import React from 'react'
import moment from 'moment'
import {Col,Row} from 'reactstrap'
import {Bar,Doughnut} from 'react-chartjs-2';
import { CapitalFirst} from '../../../../Funciones/utils'
import { useSelector } from 'react-redux';

const style={
    header:{textAlign: 'center',fontSize:'20px'},
    item:{fontSize:'16px'}
  }

  const createDonusTemplate=()=>{
    return {
        labels: [
          'Pagado',
          'Devuelto'
        ],
        datasets: [{
          data: [300, 50],
          backgroundColor: [
          '#80ffcc',
          '#FA8072',
          
          ],
          hoverBackgroundColor: [
          '#80ffcc',
          '#FA8072',
          ]
        }]
      };
    }
    var barChartData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
            label: 'Dataset 1',
            backgroundColor: 'red',
            data: [
                2,
                2,
                2,
                2,
                2,
                2,
                2
            ]
        }, {
            label: 'Dataset 2',
            backgroundColor: 'blue',
            data: [
                2,
                2,
                2,
                2,
                2,
                2,
                2
            ]
        }, {
            label: 'Dataset 3',
            backgroundColor: 'green',
            data: [
                2,
                2,
                2,
                2,
                2,
                2,
                2
            ]
        }]
    }
    
const Graficos=({fecha,labels,datasets,listaPagos,tipoPagos})=>{
const {preciosXFecha,tipoPrecio}=useSelector(x=>x.mainReducer)
barChartData.labels=labels
barChartData.datasets=datasets
const precioElegido=preciosXFecha.find(x=>x.type==tipoPrecio);
if(!precioElegido){
    return<React.Fragment></React.Fragment>
}

const donusActualPagos=createDonusTemplate()

donusActualPagos.labels=tipoPagos.map(x=>CapitalFirst(x.tipo))
donusActualPagos.datasets[0]={data:tipoPagos.map(tipo=>listaPagos.filter(x=>!x.isRefund).filter(x=>x.method==tipo.tipo).reduce((acom,current)=>acom+current.amount,0))}
donusActualPagos.datasets[0].backgroundColor=tipoPagos.map(x=>x.backgroundColor)
return(
  <React.Fragment>
      <Row>
        <Col xs={12} md={6}>
        <Bar data={barChartData} options={{title:{text:moment(fecha).format('dddd DD/MM/YYYY'),display:true,fontSize:18},responsive:true,scales: {
                            xAxes: [{
                                stacked: true,
                            }],
                            yAxes: [{
                                stacked: true
                            }]
                        }
                    }}/>
        </Col>
        <Col xs={12} md={6} style={style.header}>
            <Doughnut data={donusActualPagos} options={{title:{text:'Formas de pago',display:true,fontSize:18,responsive:true}}}/>
        </Col>
      </Row>

  </React.Fragment>
    //MANANA SACAR USUARIO UNDEFINED
)
}

export default Graficos
