import React, { useState } from "react"
import {chooseCurrency} from "./../../.././../Funciones/utils"
import moment from "moment"

const PrecioInput = ({
    precio,
    fecha,
    currency,
    nombrePrecio,
    startDrag,
    setStartDrag,
    setOpenPopup,
    backgroundColor
  }) => {
    return (
      <React.Fragment>
        <div
          // onMouseOver={() => {
          //   //Si la fecha es mayor a la fecha que hay en setdrag remplazarla
          //   if (startDrag.start && startDrag.fechaFin != fecha) {
          //     if (
          //       moment(startDrag.fechaInicio).diff(moment(fecha), "days") >= 0
          //     ) {
          //       setStartDrag({ ...startDrag, fechaInicio: fecha })
          //     } else {
          //       setStartDrag({ ...startDrag, fechaFin: fecha })
          //     }
          //   }
          // }}
          // onMouseUp={() => {
          //   setOpenPopup(true)
          //   if (startDrag.fechaInicio) {
          //     setStartDrag({ ...startDrag, start: false })
          //   }
          // }}
          // onMouseDown={() => {
          //   setStartDrag({
          //     nombrePrecio,
          //     fechaInicio: fecha,
          //     fechaFin: fecha,
          //     start: true,
          //   })
          // }}
          className={`Noselect rna-grid__cell rna-value-wrapper js-rna-cell js-drag-and-select rna-grid__cell--actionable ${
            startDrag.nombrePrecio == nombrePrecio &&
            moment(fecha).isBetween(
              startDrag.fechaInicio,
              startDrag.fechaFin,
              "day",
              "[]"
            )
              ? `Select`
              : ""
          }`}
          data-date="2019-11-06"
          style={{
            backgroundColor:
              backgroundColor,
          }}
        >  
          <span  className={`rna-value rna-value--bottom rna-value--caption `}>
            <div className="rna-value--currency">{chooseCurrency(currency)}</div>
            {precio}
          </span>
        </div>
      </React.Fragment>
    )
  }


const ListaPrecios = ({listaFechas, payedDates, pricePerDate, unpayedDates, selectedDates, defaultCurrency} ) => {
    const [openPopup, setOpenPopup] = useState(false)
    const [startDrag, setStartDrag] = useState({})
    return(
      <div
      className="rna-grid__row js-onboarding-prices js-rna-bulk-form-row js-observe-visibility"
      data-render-id="rna-room-block"
      data-rate-id={1799663}
      data-occupancy={2}
      id="rate-status-55835201-1799663"
      data-form-type="rates"
      style={{ height: "35px" }}
    >
      <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left">
        <span 
          className="rna-titlerna-title--expandable bui-link bui-link--primary"
          title="ID de tarifa: 1799663"
          data-on-click="toggleRateCategory('55835201','1799663',null,0,0)"
          style={{float:"right",height: "35px"}}

        >
        
        </span>
      </div>
    {listaFechas.map((x) => (<PrecioInput
    setStartDrag={setStartDrag}
    startDrag={startDrag}
      key={x}
      precio={pricePerDate[x]}
      fecha={x}
      currency={defaultCurrency}
      // if is unpaid color yellow
      backgroundColor={payedDates.includes(x) ? "#65ff8966" : unpayedDates.includes(x) ? (selectedDates.includes(x)? "rgb(177 175 5 / 40%)" :"rgb(255 253 7 / 40%)") : selectedDates.includes(x) ? "rgb(159 159 159 / 40%)" : "white"}
      nombrePrecio={"precio"}
      />
    ))}
    </div>
    )
}


export default ListaPrecios