import React from 'react';
import MaterialTable from 'material-table';
import {connect} from 'react-redux'
import {Normalizar} from '../../../../Funciones/utils'
import { v4 as uuidv4 } from 'uuid';

const CorroborarData=(data)=>{
  console.log(data)
    if (
     (!data.brand&&
    !data.plate&&
    !data.color)
    ||
    (data.brand?.trim()==""&&
    data.plate?.trim()==""&&
    data.color?.trim()=="")){
      console.log(data.brand?.trim(),data?.brand=="")
      alert("Al menos un campo debe estar completo")
      
      return false;
    }
    else{
        return true;
    }
}

 const MaterialTableDemo= ({data,setData,reservationId,precios,dispatch,tipoPrecio})=> {
  let columns= [
      { title: 'Patente', field: 'plate' },
      { title: 'Marca', field: 'brand' },
      { title: 'Color', field: 'color' }]
  const vehicles=precios.find(x=>x.type==tipoPrecio).priceList.filter(x=>x.vehicleBased);
  if (vehicles.length>0) {
    const lookup={}
    vehicles.forEach((element) => {
      lookup[element.name]=element.name
    });
    columns=[...columns,{ title: 'Tipo', field: 'type',lookup,initialEditValue:vehicles[0].name }]
  }
  return (
    <MaterialTable
      title="Vehiculos"
      columns={columns}
      data={data}
      style={{textAlign:"right !important",padding:'10px'}}
      localization={{
            body:{
            addTooltip:"Agregar Vehiculo",
            emptyDataSourceMessage:"No se cargaron vehiculos",
            deleteTooltip:"Eliminar",
            editTooltip:"Editar",
            editRow:{
            deleteText:"Seguro que quieres eliminar el vehiculo?",
            cancelTooltip:"Cancelar",
            saveTooltip:"Guardar"}},
            header:{actions:"Acciones"}}}
      options={{
        showTitle:false,
        paging:false,
        search: false,
        sorting:false,
        draggable:false
      }}
      editable={{
        onRowAdd: newData =>{

            if (CorroborarData(newData))
            {
                const type=vehicles.length==0?"auto":newData.type.trim();
                const nuevaLista=[...data,{vehicleId:uuidv4(),brand:newData.brand?Normalizar(newData.brand.trim()):"",color:newData.color?Normalizar(newData.color.trim()):"",plate:newData.plate?newData.plate.trim().split(' ').join("").split("-").join("").split("'").join("").toUpperCase():'',type}];
                setData(nuevaLista);
                
            }
           return( new Promise(resolve => {
                resolve();    
        }))},

        onRowUpdate: (newData, oldData) =>
        {

            if (oldData&&CorroborarData(newData)) {
                const type=vehicles.length==0?"auto":newData.type.trim();
                const nuevaLista=data.map(x=>x==oldData?{
                  vehicleId:uuidv4(),
                  brand:newData.brand?Normalizar(newData.brand.trim()):"",
                  color:newData.color?Normalizar(newData.color.trim()):"",
                  plate:newData.plate?newData.plate.trim().split(' ').join("").split("-").join("").split("'").join("").toUpperCase():'',type}:x);
                setData(nuevaLista)}
                return(new Promise(resolve => {
              resolve();
          }))},

        onRowDelete: oldData =>
          new Promise(resolve => {

              resolve();
              setData(data.filter(x=>x!=oldData));

          }),
      }}
    />
  );
}

export default connect(state=>({precios:state.mainReducer.precios,tipoPrecio:state.mainReducer.tipoPrecio}),null)(MaterialTableDemo)

