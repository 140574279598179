import React,{useState,useEffect} from "react"
import LoadingOverlay from 'react-loading-overlay'
import {Modal, ModalHeader, ModalBody, Container,Col,Row} from 'reactstrap'
import {connect} from 'react-redux'
import moment from 'moment'
import StepWizard from 'react-step-wizard'
import Caja from './caja'
import Reserva from './reserva'
import {CalcularPrecios} from './calcularPrecios'
import Facturacion from './facturacion'
import { Menu } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import { Icon,Form, TextArea,Label,Dropdown } from 'semantic-ui-react'
import {CambiarEstadoReserva,PersonasEnRegistro, ElegirLabel,CambiarEstadoCheckinReserva,ElegirOtaLabel,GeneratePrintTemplate,copyPrintReservation,printPOS,connectPrinter, chooseStarredCustomer, ChangeTypeSelectWithDuplication} from '../../../Funciones/utils'
import { Button} from 'semantic-ui-react'
import printJS from 'print-js'
import ModalBanEncontrado from "./baneados/modalBanEncontrado"
import ModalCrearBan from "./baneados/modalCrearBan"
//Actions
import setModal from '../../../redux/actions/setModalReserva'

const ReiniciarModal=(dispatch,setActiveItem,stepWizard)=>{
  setActiveItem("pagos")
  stepWizard.goToStep(1)
  dispatch(setModal(false))
}

export const ModalAux = ({billSession,togglePrinter,diaPlaya,openModal,dispatch,reserva,isMobile,isConnecting,isPrinting,isConnected}) => {
  const [nuevaReserva,setNuevaReserva]=useState(false);
  const [stepWizard,setStepWizard]=useState({});
  const [notas,setNotas]=useState("");
  const [loading,setLoading]=useState(false);
  const [activeItem,setActiveItem]=useState("reserva");
  const [openPOS,setOpenPOS]=useState(false);

  useEffect(()=>{
    if (reserva.nuevaReserva){
      setNuevaReserva(true)
    }
    else{
      setNuevaReserva(reserva.nuevaReserva )
      setNotas(reserva.description ?? '')
      if (!openModal) {
        setActiveItem(reserva.customersList?.length==0?"reserva":"pagos")

      }
    }
  
  },[reserva,reserva.nuevaReserva])


  const goToPayment=()=>{setActiveItem("pagos");stepWizard.goToStep(1);document.querySelector("div.modal.fade.show").scrollTo({ top: 0, behavior: 'smooth' })}
  const starredCustomer=chooseStarredCustomer(reserva?.customersList,reserva?.starredCustomerId)
  const printReservation=()=>{
    const printTemplate = GeneratePrintTemplate({
     reservation:reserva})
    printJS({
      targetStyle:['text-align','font-size','display','justify-content'],
      type:'raw-html',printable: printTemplate})
  }

  const printReservationCopy=()=>{
    const printTemplate = GeneratePrintTemplate({
      reservation:reserva})
    copyPrintReservation(printTemplate)
  }
  return (
    <Modal style={{maxWidth:"1200px"}} size={"lg"} isOpen={openModal} toggle={()=>{if(reserva.calcularPrecio||!nuevaReserva||window.confirm('¿Salir sin guardar cambios?')){ReiniciarModal(dispatch,setActiveItem,stepWizard);}}} >
    <LoadingOverlay
    active={loading}
    spinner
    text='Agregando'
    >
    {!reserva.calcularPrecio&&<ModalHeader style={{borderBottom:"none", paddingBottom:"0px"}} toggle={()=>{if(reserva.calcularPrecio||!nuevaReserva||window.confirm('¿Salir sin guardar cambios?')){ReiniciarModal(dispatch,setActiveItem,stepWizard);}}}
    >{!nuevaReserva?`Cuenta de ${starredCustomer?.fullName}`:""}
    {!nuevaReserva&&<>
    {ElegirOtaLabel(reserva.way) }
    {ElegirLabel(reserva.state,reserva.nightsDue)}
    <ChangeTypeSelectWithDuplication currentType={reserva.type}/>
    <br></br>
    <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
    <div>
      {Boolean(reserva?.nights)&&<Label color={'teal'} horizontal>🌙 Noches: {reserva.nights}</Label>}
      <Label color={'teal'} horizontal>🕒 Creada: {moment(reserva.created).format("DD MMM hh:mm a")}</Label>
      {reserva?.checkinMade&&<Label color={'teal'} horizontal>🕒 Checkin: {moment(reserva.checkinMade).format("DD MMM hh:mm a")}</Label>}
      {reserva.state=="confirmada"&&<Label color={'teal'} horizontal>🕒 Checkin: {moment(reserva.checkinEstimated).format("DD MMM YYYY")}</Label>}
      {reserva?.checkoutMade&&<Label color={'teal'} horizontal>🕒 Checkout: {moment(reserva.checkoutMade).format("DD MMM hh:mm a")}</Label>}
      </div>
      <div>
      <Label color={'teal'} horizontal>👱 {PersonasEnRegistro(reserva)}</Label>
      {reserva?.roomsList?.map(x=><Label color={'teal'} horizontal>
        {reserva.type=="camping"?`🏕️`:`🏠`} {x.number}
      </Label>)}
      {reserva?.vehiclesList?.map(x=><Label color={'teal'} horizontal>
        {`🚍`} {`${x.brand || ""} ${x.plate || ""}`}
      </Label>)}
      </div>

    </div>
    </>}
    </ModalHeader>}
    {reserva.calcularPrecio&&<ModalHeader style={{borderBottom:"none", paddingBottom:"0px"}} toggle={()=>{if(reserva.calcularPrecio||!nuevaReserva||window.confirm('¿Salir sin guardar cambios?')){ReiniciarModal(dispatch,setActiveItem,stepWizard);}}} ></ModalHeader>}
    <ModalBody>
    {!nuevaReserva&&<>
    {!reserva.calcularPrecio&&<Menu tabular={!isMobile} stackable>
    <Menu.Item
          active={activeItem === 'pagos'}
          onClick={()=>{setActiveItem("pagos");stepWizard.goToStep(1)}}
        >Pagos<Icon size='large' style={{marginLeft:"5px"}} name='money bill alternate'/></Menu.Item>
      <Menu.Item
          active={activeItem === 'reserva'}
          onClick={()=>{setActiveItem("reserva");stepWizard.goToStep(2)}}
        >Datos<Icon size='large' style={{marginLeft:"5px"}} name='address book'/></Menu.Item>
      <Menu.Item
          active={activeItem === 'notas'}
          onClick={()=>{setActiveItem("notas");stepWizard.goToStep(3)}}
        >Notas<Icon size='large' style={{marginLeft:"5px"}} name='edit'/>{reserva.description&&<Label color='red'>!</Label>}
        </Menu.Item>
      {billSession.toggleBilling&&<Menu.Item
          style={!isMobile?{ borderRadius: "5% 5% 0 0%"}:{}}
          active={activeItem === 'facturacion'}
          onClick={()=>{setActiveItem("facturacion");stepWizard.goToStep(4)}}
        >Facturacion<Icon size='large' style={{marginLeft:"5px"}} name='file alternate'/></Menu.Item>}
      <Menu.Item
          className={"custombutton"}
          style={!isMobile?{ borderRadius: "5% 5% 0 0%"}:{}}
          position='right'
        >    
        <Dropdown text="Exportar"  simple>
        <Dropdown.Menu>
          <Dropdown.Item onClick={()=>{
              printReservation();
          }}>
            Imprimir / PDF
            <Icon style={{float:"left"}} name="print" />
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={()=>{
              printReservationCopy()
            }}>
            Copiar al portapapeles
            <Icon style={{float:"left"}} name="copy" />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown></Menu.Item>
      {togglePrinter&&<Menu.Item
          className={isConnected?"custombutton":"POSDisabled"}
          style={!isMobile?{ borderRadius: "5% 5% 0 0%"}:{}}
          onClick={()=>{
            setOpenPOS(!openPOS)
          }}
        >
            <Dropdown loading={isConnecting||isPrinting} text="Comandera" simple open={openPOS} openOnFocus={false}>
              <Dropdown.Menu>
                <Dropdown.Item disabled={!isConnected} onClick={()=>{
                    printPOS(reserva,"checkin")
                }}>
                  Checkin
                  <Icon style={{float:"left"}} name="sign-in" />
                </Dropdown.Item>
                <Dropdown.Item disabled={!isConnected}
                  onClick={()=>{
                    printPOS(reserva,"checkout")
                  }}>
                  Checkout
                  <Icon style={{float:"left"}} name="sign-in" />
                </Dropdown.Item>
                <Dropdown.Divider />
                {/* Re connect icon */}
                <Dropdown.Item disabled={isConnected}
                  onClick={()=>{
                    connectPrinter()
                  }}>
                  <Icon name='redo' />
                  <span className='text'>Reconectar</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

        </Menu.Item>}
      <Menu.Item
          className={"custombutton"}
          style={!isMobile?{ borderRadius: "5% 5% 0 0%"}:{}}
          active={activeItem === 'duplicar'}
          onClick={()=>{if (window.confirm(`¿Seguro que quieres empezar un nuevo registro a partir de los datos de ${reserva.customersList[0].fullName}?`)) {
            dispatch({type:'DUPLICATE_RESERVATION',payload:reserva})
            stepWizard.goToStep(1)
          }}}
        >Reingresar<Icon size='large' style={{marginLeft:"5px"}} name="redo"/></Menu.Item>
      {reserva.state=="checkin"&&<Menu.Item
          className={"checkoutButton"}
          style={!isMobile?{ borderRadius: "5% 5% 0 0%"}:{}}
          active={activeItem === 'Checkout'}
          onClick={()=>{if(window.confirm("Confirmar checkout?"))CambiarEstadoCheckinReserva(reserva.reservationId,'checkout',setLoading,dispatch)}}
        >Checkout<Icon size='large' style={{marginLeft:"5px"}} name='sign-out'/></Menu.Item>}
      {reserva.state=="confirmada"&&<Menu.Item
          className={"checkinButton"}
          style={!isMobile?{ borderRadius: "5% 5% 0 0%"}:{}}
          active={activeItem === 'Checkin'}
          onClick={()=>{if(window.confirm("Confirmar checkin?"))CambiarEstadoCheckinReserva(reserva.reservationId,'checkin',setLoading,dispatch)}}
        >Checkin<Icon size='large' style={{marginLeft:"5px"}} name='sign-in'/></Menu.Item>}
    </Menu>}
    </>}
    
  {/* remove slide effect stepWizard */}
      <StepWizard instance={(e)=>setStepWizard(e)} transitions={{}} >
          {reserva.calcularPrecio&&<CalcularPrecios/>}
          {!nuevaReserva&&<Caja goToFacturacion={()=>{setActiveItem("facturacion");stepWizard.goToStep(4)}} nombreCuenta={reserva?.customersList?.length>0?reserva.customersList[0].fullName:''} reserva={reserva} ReiniciarModal={()=>{ReiniciarModal(dispatch,setActiveItem,stepWizard)}}/>}
          <Reserva  reserva={reserva} nuevaReserva={nuevaReserva} goToPayment={diaPlaya?()=>{dispatch({type:'SET_MODAL_RESERVA',payload:false})}:goToPayment}/> 
          {!nuevaReserva&& 
          <Form>
            <TextArea value={notas} onChange={(e)=>{setNotas(e.target.value)}} placeholder='Escribir notas..'/>
            <Container>
            <br></br>
            <Row>
              <Col style={{display: "contents"}}>
                <Button size={isMobile?'massive':'big'} color='teal' fluid onClick={()=>{CambiarEstadoReserva({...reserva,description:notas,checkoutEstimated:moment(reserva.checkoutEstimated).format()},()=>{},()=>{setActiveItem("pagos");stepWizard.goToStep(1)},dispatch);}}>Guardar</Button></Col>
            </Row>
         </Container>
         </Form>}
         {!nuevaReserva&&billSession.toggleBilling&&<Facturacion />}
         
      </StepWizard>
    </ModalBody>
    </LoadingOverlay>
    <ModalCrearBan />
    <ModalBanEncontrado />
  </Modal>
   
    )
}



export default connect(state=>({isConnecting:state.printerReducer?.printer?.isConnecting,isPrinting:state.printerReducer?.printer?.isPrinting,isConnected:state.printerReducer?.printer?.isConnected,togglePrinter:state.printerReducer.togglePrinter,billSession:state.billReducer,diaPlaya:state.mainReducer.diaPlaya,withoutPlace:state.mainReducer.withoutPlace,checkoutTime:state.mainReducer.checkoutTime,preReserva:state.mainReducer.preReserva,openModal:state.mainReducer.modal,reserva:state.mainReducer.reserva,isMobile:state.mainReducer.isMobile}),null)(ModalAux)

