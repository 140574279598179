import React,{useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Menu,Icon,Search} from 'semantic-ui-react'
import {CapitalFirst} from '../../../Funciones/utils'

const TipoMenu=({isMobile,sidebarOpened})=>{
    const {precios,tipoPrecio,searchResult,searchLoading}=useSelector(x=>x.mainReducer)
    const dispatch=useDispatch();
    const [searchValue,setSearchValue]=useState('');
    const search=(search)=>{
      const MAX_CHARACTERS=20;
      if (search.length<MAX_CHARACTERS) {
        setSearchValue(search);
        if (search!=undefined&&search.trim()!=""&&search.length>1) {
          dispatch({type:'SEARCH',payload:{searchThing:search}})
        }  
      }
    }
    const preciosXFechaWithMerge = precios.map(x=>({...x, title:x.type})).reduce((acc,cur)=>{
      if (cur.mergeRoomType && !acc.find(x=>x.mergeRoomType == cur.mergeRoomType)) {
        return [...acc,{...cur,title:cur.mergeRoomType}]
      }
      else if (!cur.mergeRoomType) {
        return [...acc,cur]
      }
      return acc
    },[])

    return(
    <Menu pointing secondary>
            {preciosXFechaWithMerge.length!=1&&preciosXFechaWithMerge.filter(x=>x.type.split('-').length==1).map((x,i)=>
                    <Menu.Item
                        style={{padding:isMobile?'5px':""}}
                        key={i}
                        name={isMobile?CapitalFirst(x.title).slice(0,4):CapitalFirst(x.title)}
                        active={tipoPrecio === x.type}
                        onClick={()=>{
                          dispatch({type:"CHANGE_PRICE_TYPE",payload:x.type})
                        }}
                    />
            )} 
    <Menu.Item 
        position='right'>
          {!isMobile&&<Search
          noResultsMessage={'No se encontraron registros'}
          loading={searchLoading}
          aligned={'center'}
          input={{placeholder:'DNI | Nombre | Patente'}}
          style={{margin:'auto'}}
          size={isMobile?'large':''}
          minCharacters={'2'}
          onSearchChange={(e)=>{search(e.target.value)}}
          results={searchResult}
          value={searchValue}
        /> }   
        </Menu.Item>

        <Menu.Item
          style={{marginBottom:'5px',padding:isMobile?'5px':""}}
          name='Actualizar'
          active={false}
          onClick={()=>{
          dispatch({type:'RELOAD_PRICE_LIST'});
          dispatch({type:'RELOAD_RESERVATIONS'});
          dispatch({type: "SEND_ALL_QUEUES" });}}
        >
            <Icon size={isMobile?'big':'large'} name={'redo'}></Icon>
        </Menu.Item>
        {isMobile&&<Menu.Item
              style={{padding:isMobile?'5px':""}}
              onClick={()=>{dispatch({type:'SET_SIDEBAR',payload:!sidebarOpened})}}
          ><Icon size={'big'} name={'bars'}></Icon></Menu.Item>}

    </Menu>
    )
}

export default TipoMenu