import React,{ useState,useEffect, useRef } from "react"
import { Button,Col,Row,Input,InputGroupText,InputGroup,InputGroupAddon,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { Radio,Header } from 'semantic-ui-react'
import { useDispatch,useSelector } from "react-redux";
import { Fragment } from "react";
import {CapitalFirst,TransformToLocalCurrency, printCashWithdraw} from '../../../Funciones/utils'
import DateRangePicker from "../extras/dateRangePicker";
import moment from "moment";

export const ModalRangoFechas = ({open,setOpen, setFechaInicio, setCantidadDias}) => {
const dispatch = useDispatch()

const initAll=()=>{
    setOpen(false);
}
const startRef = useRef();
const endRef = useRef();
return (
    <React.Fragment>

      <Modal style={{maxWidth:"600px"}} isOpen={open} toggle={()=>{initAll()}} >
          <ModalBody>
          <Col xs={12}>
            <DateRangePicker startRef={startRef} endRef={endRef} />
          </Col>
          </ModalBody>
          <ModalFooter>
          <Button
              size={"big"}
              style={{ backgroundColor: "#17a2b8", color: "white", border: "none" }}
              onClick={() => {
                const inicioFixed = moment.utc(startRef.current.value
                  .split("/")
                  .reverse()
                  .join("-")).format()
                const salidaFixed =  moment.utc(endRef.current.value
                  .split("/")
                  .reverse()
                  .join("-")).format()
                setFechaInicio(inicioFixed);
                dispatch({
                  type: "RELOAD_RESERVATION_LIST_SCHEDULER",
                  payload: { startDate: inicioFixed, endDate: salidaFixed },
                });
                setCantidadDias(
                  moment.utc(salidaFixed).diff(moment.utc(inicioFixed), "days")
                );
                setOpen(false)
              }}
            >
              Buscar
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
   
    )
}



export default ModalRangoFechas

