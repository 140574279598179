import React, { useState, useEffect, useMemo } from "react"
import moment from "moment"
import { ListaFechas } from "../../../../Funciones/utils"

export const Dia = ({
    texto,
    style,
    isWeekend,
    startDrag,
    setStartDrag,
    fecha,
    setOpenPopup,
    isDispo,
    isRepublicar,
    isEstadiaMinima,
    isEstadiaMaxima,
  }) => {
    return (
      <React.Fragment>
        <div
          // onMouseOver={() => {
          //   //Si la fecha es mayor a la fecha que hay en setdrag remplazarla
          //   if (
          //     (isDispo || isRepublicar|| isEstadiaMinima||isEstadiaMaxima) &&
          //     startDrag?.start &&
          //     startDrag?.fechaFin != fecha
          //   ) {
          //     setStartDrag({ ...startDrag, fechaFin: fecha })
          //   }
          // }}
          // onMouseUp={() => {
          //   if (isDispo || isRepublicar || isEstadiaMinima || isEstadiaMaxima) {
          //     setOpenPopup(true)
          //   }
          //   if (startDrag?.fechaInicio) {
          //     setStartDrag({ ...startDrag, fechaFin: fecha, start: false })
          //   }
          // }}
          // onMouseDown={() => {
          //   if (isDispo || isRepublicar || isEstadiaMinima || isEstadiaMaxima) {
          //     setStartDrag({
          //       isDispo,
          //       isRepublicar,
          //       isEstadiaMinima,
          //       isEstadiaMaxima,
          //       fechaInicio: fecha,
          //       start: true,
          //     })
          //   }
          // }}
          className={`Noselect rna-grid__cell rna-value-wrapper js-drag-and-select js-rna-cell rna-grid__cell--actionable ${
            ((startDrag?.isRepublicar && isRepublicar) ||
            (startDrag?.isEstadiaMinima && isEstadiaMinima) ||
            (startDrag?.isEstadiaMaxima && isEstadiaMaxima) ||
              (startDrag?.isDispo && isDispo)) &&
            moment(fecha).isBetween(
              startDrag.fechaInicio,
              startDrag.fechaFin,
              "day",
              "[]"
            )
              ? `Select`
              : ""
          }`}
          data-date="2019-10-07"
          style={{backgroundImage:moment().isSame(fecha,"day")?"-webkit-linear-gradient(224deg, #0089ff 10%, #ffffff 0%)":""}}

        >
          {/* <input className="rna-input js-rna-cell-input rna-input--hidden" id="rna-rts-input-55835201-2019-10-07" defaultValue={1} data-at-type="rts" data-at-room-id={55835201} data-at-date="2019-10-07" data-on-click="selectInput(event)" data-preserve-el-when-focused type="number" min={0} step={1} data-on-keyup="handleSubmitOrCancelRTS('55835201', null, '2019-10-07', event)" data-on-blur="updateRoomsToSell('55835201', null, '2019-10-07', event)" /> */}
          <span style={style} className="rna-value rna-value--bottom">
            {texto}
          </span>
        </div>
      </React.Fragment>
    )
  }


const ListaDias = ({listaFechas}) => {
    const [startDrag, setStartDrag] = useState({})
    const [openPopup, setOpenPopup] = useState(false)
    
    return(
    <div
    className="rna-grid__row js-rna-bulk-form-row js-onboarding-rooms"
    data-render-id="rna-room-block"
    data-form-type="rooms_to_sell"
    id="rooms-to-sell-55835201"
    >
        <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left">
          <div className="rna-title" style={{float:"right", height:"20px"}} ></div>
        </div>
        {listaFechas.map((x,i)=><Dia key={i} texto={moment(x).format("dd D")} fecha={x} startDrag={startDrag} setStartDrag={setStartDrag} setOpenPopup={setOpenPopup} isDispo={false} isRepublicar={false} isEstadiaMinima={false} isEstadiaMaxima={false}   isWeekend={
                      moment(x).isoWeekday() === 6 ||
                      moment(x).isoWeekday() === 7
                    }/>)}
    </div>
    )
}


export default ListaDias