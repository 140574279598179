import React,{useState,useEffect} from "react"
import { useSelector,useDispatch } from 'react-redux'
import { DevolverComponentesParaJSX } from '../../../Funciones/utils'
import Caja from './caja'
import moment from 'moment'

export const CalcularPrecios=()=>{
    const [fechaInicio,setFechaInicio] = useState()
    const [fechaFin,setFechaFin] = useState()
    const listaGeneralDeptos = useSelector((state) => state.mainReducer.listaGeneralDeptos);
    const tipoPrecio = useSelector((state) => state.mainReducer.tipoPrecio);
    const precios = useSelector((state) => state.mainReducer.precios);
    const mergeRoomType = precios.find(x=>x.type==tipoPrecio)?.mergeRoomType
    const allTypes =  mergeRoomType?precios.filter(x=>x.mergeRoomType==mergeRoomType):[precios.find(x=>x.type==tipoPrecio)]
    const reserva= {
        guests: 2,
        children: 0,
        checkoutEstimated:moment(fechaFin).format("YYYY-MM-DD"),
        checkinEstimated:moment(fechaInicio).format("YYYY-MM-DD"),
        servicesList:[],
        customersList:[],
        vehiclesList:[],
        nuevaReserva:true,
        calcularPrecio:true,
        components:JSON.stringify(allTypes.reduce((acc, currentType) => {
            return {
              ...acc,
              [currentType.type]: DevolverComponentesParaJSX([], [], reserva, [], currentType.type)
            };
          }, {})),
        nights:0,
        state: "",
        way: "Presencial",
        roomsList:[],
}

    useEffect(()=>{
        setFechaInicio(moment().format("YYYY-MM-DD"))
        setFechaFin(moment().add(1,'days').format('YYYY-MM-DD'))
    },[])

    return(<>
     <Caja calcularPrecio={true} reserva={reserva}/>
    </>
)}
 