import React from 'react';
import MaterialTable from 'material-table';
import {useSelector} from 'react-redux'
import wpIcon from '../../../../assets/WhatsApp_icon.png'
import { Icon } from 'semantic-ui-react';

 const TablaPersonas= ({dispatch,data,openCollapseItems})=> {
  const reserva = useSelector(state=>state.mainReducer.reserva)
  const defaultCurrency = useSelector(state=>state.mainReducer.defaultCurrency)
  const phonePrefix = defaultCurrency == "ARS"? "54" : defaultCurrency == "CLP"? "56" : defaultCurrency == "UYU"? "598" : "54";

  const isNuevaReserva = Boolean(reserva.nuevaReserva)
  
  const handleProhibirIngreso = (event, rowData) => {
    if(!isNuevaReserva){
      dispatch({type:"SET_BAN_STATUS",payload:{
        openCreateBanModal:true,
        customersList:data,
        reservationId:reserva.reservationId
      }})
    }
    }
    
   const columns= [
      { title: 'Nombre y apellido', field: 'fullName',    render: rowData => (
        <span style={{ textDecoration: rowData.banId?'line-through': "none" } }>
          {rowData.fullName}
        </span>
      ),
    },
      { title: 'Documento de identidad', field: 'dni' },
      { title: 'Edad', field: 'age' },
      { title: 'Whatsapp', field: 'phone',render:rowData=>rowData.phone?(
        <a href={`https://wa.me/${phonePrefix}`+rowData.phone?.split(" ").join("")+`?text=Hola ${rowData?.fullName?.split(' ')[0]}, `} target="_blank" rel="noopener noreferrer">
        <img style={{margin:0}} src={wpIcon} alt="whatsapp" width="30" height="30"/>
        </a>
    ):<div></div>} ,
     { title: 'Prohibir ingreso', field: 'banned',type:'boolean',render:  rowData => (
      rowData.banId?<>Prohibido</>:<Icon disabled={isNuevaReserva} style={{ margin:"auto", cursor:!isNuevaReserva?"pointer":""}} size={"large"} name={!rowData.banned?"ban":"check"} onClick={handleProhibirIngreso} color={!rowData.banned?"red":"green"}/>
      )},

    /*

   { title: 'Telefono', field: 'phone' }*/]
  return (
    <MaterialTable
      title="Personas"
      columns={columns}
      actions={[
        (rowData)=>({
          icon: rowData.customerId == reserva.starredCustomerId? "star" : "star_border",
          // color orange
          iconProps: {
            style: {
              color: rowData.customerId == reserva.starredCustomerId? "rgb(245, 176, 0)" : "black",
            }
          },
          tooltip: 'Favorito',
          onClick: (event, rowData) => {
            rowData.starred = !rowData.starred;
            dispatch({type:'STARRED_CUSTOMER',payload:rowData.customerId})
          }
        }),
        {
          icon: 'edit',
          tooltip: 'Editar',
          onClick: (event, rowData) => {
            openCollapseItems(true)
            dispatch({type:"SELECT_CUSTOMER",payload:rowData.customerId})
          }
        },
        {
          icon: 'delete',
          tooltip: 'Eliminar',
          onClick: (event, rowData) => {
            dispatch({type:"REMOVE_CUSTOMER",payload:rowData.customerId})          }
        },
      ]}
      data={data}
      style={{textAlign:"right !important",padding:'10px'}}
      localization={{
        body:{
        emptyDataSourceMessage:"No se cargaron personas"},
        header:{actions:"Acciones"}}}
      options={{
        showTitle:false,
        paging:false,
        search: false,
        sorting:false,
        draggable:false
      }}
    />
  );
}

export default TablaPersonas

