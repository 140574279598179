import React, { useState, useEffect, useMemo } from "react"
import moment from 'moment'
import { Col, Row, Input, InputGroupText, InputGroup, InputGroupAddon, Container, Button as ButtonR } from 'reactstrap';
import { ListaFechas,nightsAndPaymentForCurrentReservation,AgregarYPagarServicios,CambiarEstadoReserva,chooseStarredCustomer,getDetailedPaymentsByDate, getChargeOnceKeys} from "../../../Funciones/utils"
import DateTimelinePicker from "../extras/dateTimelinepicker"
import { connect } from 'react-redux'
import { Button, Input as SemanticInput, Divider, Icon, Popup } from 'semantic-ui-react'
import { Collapse } from 'react-collapse';
import { useSelector,useDispatch } from 'react-redux'
import ConfirmarPago from './confirmarPago'
import {roundToNearestZero} from '../../../Funciones/utils'

const ButtonsAddComponents=({componentesExtra,componentes,mostrarComponentesExtra, setMostrarItemModificado})=>{
    const [open,setOpen]=useState(false)
    const [forma,setForma]=useState(false)
    const [makeBill,setMakeBill]=useState(false)
    const {preciosXFecha, tipoPrecio,reserva,paymentLoading,isMobile } = useSelector(x => x.mainReducer)
    const customerList=useSelector(x=>x.mainReducer.reserva.customersList)
    const starredCustomerId=useSelector(x=>x.mainReducer.reserva.starredCustomerId)
    const dispatch = useDispatch()
    const description=useMemo(()=>Object.keys(componentesExtra).reduce((str,key)=>{
        if (componentesExtra[key]>0){
            return str+`${componentesExtra[key]} ${key} `
        }
        return str
    }
    ,""),[componentesExtra])
    const setLoading=(loading)=>dispatch({type:"SET_PAYMENT_LOADING",payload:loading})
    const {nights,checkin}=nightsAndPaymentForCurrentReservation()
    const listaFechas = ListaFechas(checkin, moment(checkin).add(nights-1, 'days'))
    const keys = Object.keys(componentesExtra).sort()
    const precio = listaFechas.reduce((acom, fecha) => acom + keys.reduce((acom2, comp) => acom2 + ((preciosXFecha.find(x => x.type == tipoPrecio).precios.find(x => x.name == comp)?.list.find(x => x.fecha == fecha)?.precio ?? 0) * componentesExtra[comp]), 0), 0)
    if(customerList.length==0){
        return null
    }
    const Guardar=async (setLoadingAux)=>{
        const reservaAux={...reserva,checkoutEstimated:moment(reserva.checkoutEstimated).format()}
        await CambiarEstadoReserva(reservaAux,setLoadingAux,()=>{},dispatch,JSON.stringify(componentes))
        setMostrarItemModificado(false)
    }
    return (
    <>

    <ConfirmarPago noches={nights} 
    makeBill={makeBill}
    setMakeBill={setMakeBill}
    nombreCuenta={[chooseStarredCustomer(customerList,starredCustomerId).fullName]}
    onPay={async()=>{
        
        const servicio={
            components: JSON.stringify(componentesExtra),
            cost: precio,
            date: moment().format(),
            description: `Extra ${nights>1?`${nights} noches`:`${nights} noche`} ${description}`,
            name: "extra",
            paymentsList: [],
            quantity: 0,
        }
        if (forma=="") {
          alert("falta agregar forma de pago")
        }
        else{
          setLoading(true)
          await Guardar(()=>{})
          const reservaAux={...reserva}
          reservaAux.components=JSON.stringify(componentes)
          setTimeout(() => {
             AgregarYPagarServicios(reservaAux.reservationId,servicio,dispatch,setLoading,0,{amount:precio,isRefund:false,date:moment().format(),method:forma},false);
          }, 800);
          setOpen(false)
        }
    }}
    loading={paymentLoading}
    descripcion={description} open={open} 
    setOpen={setOpen} precio={precio} forma={forma} customersList={[]} 
    setForma={setForma} />

    <Container>
        <Row style={{flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
        <Button.Group>
            {mostrarComponentesExtra&&<>
            <Button size={isMobile?'massive':'large'}  color="teal" onClick={()=>setOpen(true)}
              content={`Agregar ${description} $${precio}`} />
            {!isMobile&&<Button.Or text="o" />}
            </>}
            <Button size={isMobile?'massive':'large'}  labelPosition='left' icon='left save' content={"Guardar"} onClick={Guardar.bind(this,setLoading)} />
        </Button.Group>
        </Row>

    </Container>
    </>
    )
}

export const Precios = ({
    precio,
    setCosto,
    setHasDiscount,
    setPrecio,
    fechaInicio,
    fechaFin,
    setDescripcion,
    setComponentes,
    componentes,
    noches,
    setDiscount,
    setMostrarItemModificado
  }) => {
    const {
      isMobile,
      preciosXFecha,
      reserva,
      tipoPrecio,
      precios: priceStruct
    } = useSelector(state => state.mainReducer);
  
    const orderRankPrice = getOrderRankPrice(priceStruct, tipoPrecio);
  
    useEffect(() => {
      applyDiscounts(componentes, priceStruct, tipoPrecio, setDiscount, setCosto, setHasDiscount, precio);
    }, [reserva]);
  
    useEffect(() => {
      calculatePrice(componentes, noches, fechaInicio, fechaFin, preciosXFecha, tipoPrecio, setPrecio, setDescripcion, priceStruct);
    }, [componentes, noches, fechaInicio]);
  
  
    const handleAddComponent = (name, componentesType) => {
      updateComponent(name, componentes, priceStruct, tipoPrecio, setDiscount, setCosto, setHasDiscount, precio, setComponentes, 1, componentesType, setMostrarItemModificado);
    };
  
    const handleRemoveComponent = (name, componentesType) => {
      updateComponent(name, componentes, priceStruct, tipoPrecio, setDiscount, setCosto, setHasDiscount, precio, setComponentes, -1, componentesType, setMostrarItemModificado);
    };
    const componentsLength = Object.keys(componentes).length
    console.log(componentes)
    return (
      <Container>
        {Object.entries(componentes).sort((a, b) => a[0] > b[0] ? 1 : -1).map((x,i) => (
          <div>
          {componentsLength>1&&<span className="row" style={{textAlign:"center", fontSize:"14px"}}>{x[0]}</span>}
        <Row style={{marginBottom:"20px", boxShadow:"0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"}}>
          {Object.keys(x[1])
            .sort((a, b) => orderRankPrice[a] - orderRankPrice[b])
            .map((name, i) => (
                <ComponentCol
                  key={i}
                  name={name}
                  componentes={componentes}
                  componentesType={x[0]}
                  priceStruct={priceStruct}
                  tipoPrecio={x[0]}
                  fechaInicio={fechaInicio}
                  preciosXFecha={preciosXFecha}
                  isMobile={isMobile}
                  withBorder={Object.keys(x[1]).length>4}
                  handleAddComponent={handleAddComponent}
                  handleRemoveComponent={handleRemoveComponent}
                />
            ))}
        </Row>
        </div>
        ))}
      </Container>
    );
  };
  
  const getOrderRankPrice = (priceStruct, tipoPrecio) => {
    return priceStruct?.find(x => x.type === tipoPrecio)?.priceList.reduce((acom, comp) => ({ ...acom, [comp.name]: comp.orderRank ?? 100 }), {});
  };
  
  const applyDiscounts = (componentes, priceStruct, tipoPrecio, setDiscount, setCosto, setHasDiscount, precio) => {
    for (const structKey in componentes) {
      for (const name in componentes[structKey]) {
        if (componentes[structKey][name]  > 0) {
          const componentWithDiscount = getComponentWithDiscount(priceStruct, tipoPrecio, name);
          if (componentWithDiscount) {
            setDiscount(componentWithDiscount);
            setCosto(roundToNearestZero(Math.floor(precio * (1 - (componentWithDiscount / 100)))));
            setHasDiscount(true);
          }
        }
      }
    }
  };
  
  const getComponentWithDiscount = (priceStruct, tipoPrecio, name) => {
    return priceStruct?.find(x => x.type === tipoPrecio)?.priceList?.find(x => x.name === name)?.totalPriceDiscount;
  };
  
  const calculatePrice = (componentes, noches, fechaInicio, fechaFin, preciosXFecha, tipoPrecio, setPrecio, setDescripcion, priceStruct) => {
    const listaFechas = ListaFechas(fechaInicio, moment(fechaFin).add(noches === 0 ? 0 : -1, 'days'));
    const keys = Object.keys(componentes).reduce((acom, current) => [...acom, ...Object.keys(componentes[current])], []);
    const chargeOnceKeys = getChargeOnceKeys(priceStruct);
    const keysWithoutChargeOnce = keys.filter(x => !chargeOnceKeys.includes(x));
  
    const precio = calculatePrecio(listaFechas, keysWithoutChargeOnce, preciosXFecha, tipoPrecio, componentes, chargeOnceKeys);
  
    setPrecio(parseInt(precio));
    setDescripcion(getDescripcion(componentes));
  };
  
  
  const calculatePrecio = (listaFechas, keysWithoutChargeOnce, preciosXFecha, tipoPrecio, componentes, chargeOnceKeys) => {
    return Object.keys(componentes).map(tipoPreciox=>listaFechas.reduce((acom, fecha) => acom + Object.keys(componentes[tipoPreciox]).filter(x=>keysWithoutChargeOnce.includes(x)).reduce((acom2, comp) => acom2 + ((preciosXFecha.find(x => x.type === tipoPreciox)?.precios.find(x => x.name === comp)?.list.find(x => x.fecha === fecha)?.precio ?? 0) * (componentes[tipoPreciox][comp] ?? 1)), 0), 0) +
      chargeOnceKeys.reduce((acom2, comp) => acom2 + ((preciosXFecha.find(x => x.type === tipoPreciox)?.precios.find(x => x.name === comp)?.list.find(x => x.fecha === listaFechas.at(0))?.precio ?? 0) * (componentes[tipoPreciox][comp] || 0)), 0)).reduce((a, b) => a + b, 0)
  };
  
  function getDescripcion(input) {
    let result = '';
    for (let category in input) {
        for (let item in input[category]) {
            if (input[category][item] !== 0) {
                result += input[category][item]+' '+ item +' ';
            }
        }
    }
    return result.trim();
}
  
  const updateComponent = (name, componentes, priceStruct, tipoPrecio, setDiscount, setCosto, setHasDiscount, precio, setComponentes, increment, componentesType, setMostrarItemModificado) => {
    const componentWithDiscount = getComponentWithDiscount(priceStruct, tipoPrecio, name);
    setMostrarItemModificado&&setMostrarItemModificado(true);
    if (componentWithDiscount) {
      setDiscount(componentWithDiscount);
      setCosto(roundToNearestZero(Math.floor(precio * (1 - (componentWithDiscount / 100)))));
      setHasDiscount(true);
    }
    componentes[componentesType][name] = parseInt(componentes[componentesType][name]) + increment;
    if(componentes[componentesType][name]<0){
        componentes[componentesType][name]=0;
    }
    setComponentes({ ...componentes });
  };
  
  const ComponentCol = ({ name, componentes, componentesType, priceStruct, tipoPrecio, fechaInicio, preciosXFecha, isMobile, handleAddComponent, handleRemoveComponent, withBorder }) => {
    const componentWithDiscount = getComponentWithDiscount(priceStruct, tipoPrecio, name);
    const price = componentWithDiscount ? `-` : `$${preciosXFecha.find(y => y.type === tipoPrecio)?.precios.find(y => y.name === name)?.list.find(y => y.fecha === fechaInicio)?.precio ?? 0}`;
    const priceTitle = `${name.toUpperCase()} ${componentWithDiscount && `(${componentWithDiscount}%)` || ''}`;
    const componentesValue = componentes[componentesType][name]
    return (
      <Col xs={12} md={3} style={{backgroundColor: componentesValue !== 0 ? '#00b5ad' : '#fff', borderRadius: '5px', border: withBorder&&'2px solid white' }}>
        <Col xs={12} style={{ textAlign: 'center', size: '16px', fontWeight: 'bold', color: componentesValue !== 0 ? '#fff' : 'black' }}>
          {priceTitle}<br />{price}
        </Col>
        <Col xs={12}>
          <div className="d-flex justify-content-center">
            <SemanticInput placeholder='Cantidad'>
              <Icon onClick={() => handleRemoveComponent(name, componentesType)} name='minus' style={{ fontSize: isMobile ? '28px' : '20px', marginTop: '10px', marginRight: isMobile ? '20px' : '10px', color: "#fff" }} link={componentesValue!=0} position={'left'} />
              <input style={{ marginBottom: '10px', textAlign: 'center', width: '100px', fontSize: '16px' }} value={componentesValue}  />
              <Icon onClick={() => handleAddComponent(name, componentesType)} name='plus' style={{ fontSize: isMobile ? '28px' : '20px', marginTop: '10px', marginLeft: isMobile ? '20px' : '10px', color: componentesValue !== 0 ? '#fff' : 'black' }} link />
            </SemanticInput>
          </div>
        </Col>
      </Col>
    );
  };

export const PreciosComponent = ({mostrarItemModificado, setMostrarItemModificado, mostrarComponentesExtra,mostrarExtraGuardar,componentesExtra,vistaCompleta,setHora,hora,listaHorarios,modificarFechaIngreso, fechaIngreso,setFechaIngreso,componentes,setComponentes,reserva,costo, setCosto,setFechaSalida,servicesList,setServicio,ingreso, precios, isMobile, calcularPrecio }) => {
    const [tipo, setTipo] = useState("noches")
    const [precio, setPrecio] = useState(0)
    const [quantity, setQuantity] = useState(1)
    const [descuento, setDescuento] = useState(0)
    const [descripcion, setDescripcion] = useState("")
    const [descripcionPrecios, setDescripcionPrecios] = useState("")
    const [hasDiscount, setHasDiscount] = useState(false);
    const [abrirComponentes, setAbrirComponentes] = useState(true);
    const serviciosLimpios=servicesList.filter(x =>!x.canceled);
    const discountFunction = useSelector(x => x.mainReducer.discountFunction)
    const afternoonCheckoutTime = useSelector(x => x.mainReducer.afternoonCheckoutTime)
    const defaultCurrency = useSelector(x => x.mainReducer.defaultCurrency)
    const checkoutTime = useSelector(x => x.mainReducer.checkoutTime)
    const primerPago=serviciosLimpios.length == 0||
    (serviciosLimpios.length == 1&&serviciosLimpios[0].name=="preingreso");
    useEffect(() => {
        if (primerPago) {
            setQuantity(ingreso.nights)
            setTipo('noches')
            setAbrirComponentes(true);
        }
        else if (isMobile) {
            //setFechaSalidaAnterior(ingreso.checkoutEstimated)
            setAbrirComponentes(false);
        }
        setFechaIngreso(moment(ingreso.checkinEstimated).format("YYYY-MM-DD"))
    }, [reserva])

    useEffect(() => {
        setCosto(roundToNearestZero(parseInt(precio * (hasDiscount ? (1 - (descuento / 100)) : 1))))
    }, [quantity,precio])
    

    useEffect(() => {
        if (quantity < 0) {
            //alert("Las noches tienen que ser positivas")
            setQuantity(0)
        }
        if (tipo == "noches" || tipo == "") {
            setDescripcion(`${quantity==0?'Pasar el dia':`${quantity} ${(quantity == 1) ? "noche" : "noches"}`} ${descripcionPrecios}${descuento != 0 ? `[${descuento}% Desc]` : ""}`)
        }
        //alert('cambio ingreso')
    }, [quantity, reserva, descripcionPrecios, descuento])

    useEffect(() => {
        if (costo < 0) {
            //alert("El importe tiene que ser positivo")
            setCosto(0)
        }
    }, [costo])

    useEffect(() => {
        if (ingreso.nuevaReserva&&setHora&&quantity==0) {
            setHora(`${afternoonCheckoutTime}:00`)
        }
    }, [quantity])

    useEffect(() => {
        if (calcularPrecio) {
            setQuantity(GetNoches(moment(fechaIngreso).add(1, 'days').format('YYYY-MM-DD')))
        }
    }, [reserva, fechaIngreso])

    useEffect(() => {
        setServicio({ cost: costo, quantity:quantity, name: tipo, description: descripcion, components: JSON.stringify(componentes) ,paymentsList:[{amount:costo,method:'efectivo',isRefund:false,date:moment().format()}]})
    },[costo,quantity,descripcion,componentes])

    useEffect(() => {
        setFechaSalida(moment(primerPago ? fechaIngreso : ingreso.checkoutEstimated).add(quantity, 'days').format('YYYY-MM-DD'))
    },[quantity,fechaIngreso,ingreso])

    useEffect(() => {
        if(Boolean(discountFunction)){
            var func = new Function("nights ", discountFunction);
            const discountAcom = func(quantity);
            setDescuento(discountAcom)
            setCosto(roundToNearestZero(Math.floor(precio * (1 - (discountAcom / 100)))))
            setHasDiscount(discountAcom != 0)
        }
    },[quantity])
    const detailPaymentByDate = useMemo(()=>getDetailedPaymentsByDate(reserva.servicesList,reserva.checkinEstimated,moment(reserva.checkinEstimated).add(200,"days"),componentes||{}, reserva.nightsDue, reserva.state == 'checkin'), [reserva.servicesList,reserva.checkinEstimated,componentes,reserva.nightsDue,reserva.state])
    const payedDates = detailPaymentByDate.filter(x=>x.isPaid).map(x=>x.date)
    const deltaCheckin = moment(ingreso.checkinEstimated).add(payedDates.length,"day").format('YYYY-MM-DD')
    const fSalida = moment(primerPago ? fechaIngreso : deltaCheckin).add(quantity, 'days').format('YYYY-MM-DD');
    const deltaEntrada = moment(primerPago ? fechaIngreso : deltaCheckin).format('YYYY-MM-DD');
    const GetNoches = (fSalida) =>moment(fSalida).diff(moment(deltaEntrada).format('YYYY-MM-DD'), "days")
    const unpayedDates = detailPaymentByDate.filter(x=>x.isDue).map(x=>x.date)
    const pricePerDate = detailPaymentByDate.reduce((a,b)=>({...a,[b.date]:b.price}),{})
    const selectedDates = ListaFechas(deltaEntrada, fSalida)
    selectedDates.pop()
    
    // Media estadia
    const isMediaEstadia = parseInt(hora) >= afternoonCheckoutTime;
    const checkSetHour = ()=>setHora(!isMediaEstadia ? `${afternoonCheckoutTime}:00`:`${checkoutTime<10?`0${checkoutTime}`:checkoutTime}:00`)
    return (
        <React.Fragment>
            <Collapse isOpened={abrirComponentes}>
                {componentes && <Precios setMostrarItemModificado={setMostrarItemModificado} hasDiscount={hasDiscount} precio={precio} setCosto={setCosto} setHasDiscount={setHasDiscount} discount={descuento} setDiscount={setDescuento} fechaInicio={deltaEntrada} noches={quantity} fechaFin={fSalida} ingreso={ingreso} precios={precios} setPrecio={setPrecio} setDescripcion={setDescripcionPrecios} setComponentes={setComponentes}
                    componentes={componentes} />}
            </Collapse>
            
         
            {abrirComponentes && <Divider horizontal><Icon onClick={() => { setAbrirComponentes(!abrirComponentes) }} style={{ fontSize: isMobile ? '2.7rem' : '1.8rem', cursor: 'pointer' }} name='arrow circle up' size='small' /></Divider>}
            {!abrirComponentes && <Divider horizontal><Icon onClick={() => { setAbrirComponentes(!abrirComponentes) }} style={{ fontSize: isMobile ? '2.7rem' : '1.8rem', cursor: 'pointer' }} name='arrow circle down' size='small' /></Divider>}
            {mostrarItemModificado && <ButtonsAddComponents componentesExtra={componentesExtra} mostrarComponentesExtra={mostrarComponentesExtra} setMostrarItemModificado={setMostrarItemModificado} componentes={componentes}/>}
            <Row>
                {vistaCompleta&&<Col xs={12} md={{ offset: 1, size: 3 }}>
                    <h3 style={{ textAlign: 'center' }}>{tipo == 'noches' ? 'Agregar noches' : 'Cantidad'}</h3>
                    <Col xs={12}>
                        <div className="d-flex justify-content-center">
                            <SemanticInput placeholder='Cantidad'>
                                <Icon onClick={() => {
                                    if (quantity > 0) {
                                        setQuantity(parseInt(quantity) - 1)
                                    }
                                }} name='minus' style={{ fontSize: isMobile ? '28px' : '20px', marginTop: '10px', marginRight: isMobile ? '20px' : '10px', color: "black" }} link position={'left'} />
                                <input type="number" pattern="[0-9]*" style={{ marginBottom: '10px', textAlign: 'center', width: '120px', fontWeight: '800', fontSize: "18px" }} value={quantity} onChange={(e) => { setQuantity(e.target.value || 0) }} />
                                <Icon onClick={() => { setQuantity(parseInt(quantity) + 1) }} name='plus' style={{ fontSize: isMobile ? '28px' : '20px', marginTop: '10px', marginLeft: isMobile ? '20px' : '10px', color: "black" }} link />
                            </SemanticInput>
                        </div>
                    </Col>

                </Col>}
                <Col xs={12} md={4} >
                    <h3>Descripcion</h3><Input type="textarea" value={descripcion} onChange={(e) => { setDescripcion(e.target.value) }}
                    />
                </Col>
                <Col xs={12} md={4}>
                    <Row style={{ marginBottom: "10px", marginTop: "10px" }}>
                        <Col xs={5}> <h3>Valor</h3></Col>
                        {<Col xs={7}> <h3 style={{ float: "right" }}>${parseInt((precio * (hasDiscount ? (1 - (descuento / 100)) : 1) / (quantity == 0 ? 1 : quantity)))} x noche</h3></Col>}
                    </Row>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>$</InputGroupText>
                        </InputGroupAddon>
                        <Input type="number" pattern="[0-9]*" style={{ fontSize: "26px", fontWeight: 800 }} value={costo} onChange={(e) => {
                            if (hasDiscount) {
                                const descuent = 100 - Math.ceil(100 * e.target.value / (precio));
                                setDescuento(descuent < 0 ? 0 : descuent);
                            }
                            setCosto(parseInt(e.target.value))
                        }
                        } />
                        <InputGroupAddon addonType="append">
                            <ButtonR 
                                id={"buttonDisc"} style={{ float: "right" }} onClick={() => {
                                    setHasDiscount(!hasDiscount)
                                    const num = descuento == 0 ? 10 : 0;
                                    setDescuento(num);
                                    const descuent = num > 100 ? 100 : num;
                                    setCosto(roundToNearestZero(parseInt(precio * (1 - (descuent / 100)))))
                                }} color="info" size={"lg"}
                            >%</ButtonR>
                        </InputGroupAddon>
                        {descuento != 0 &&
                            <Input type="number" pattern="[0-9]*" style={{ fontSize: "26px", fontWeight: 800 }} value={descuento} onChange={(e) => {
                                const descuent = e.target.value > 100 ? 100 : e.target.value;
                                setCosto(roundToNearestZero(parseInt(precio * (1 - (descuent / 100)))))
                                setDescuento(descuent)
                            }
                            } />
                        }
                    </InputGroup>
                </Col>
            </Row>
            <br></br>
            <DateTimelinePicker defaultCurrency={defaultCurrency} selectedDates={selectedDates} pricePerDate={pricePerDate} payedDates={payedDates} unpayedDates={unpayedDates} startDate={detailPaymentByDate.at(0).date} endDate={detailPaymentByDate.slice(0,isMobile?4:14).at(-1).date}/>
            <br></br>
            <Row>
                {vistaCompleta&&<Col xs={12} md={{ size: 3, offset: 2 }}> <h3>Pagar desde</h3><Input disabled={!calcularPrecio&&!modificarFechaIngreso} type="date" value={deltaEntrada} onChange={(e) => {setFechaSalida(moment(e.target.value).add(1, 'days').format('YYYY-MM-DD')); setFechaIngreso(e.target.value)}}></Input></Col>}
                {vistaCompleta&&<Col xs={12} md={{ size: 3 }}> <h3>Pagar hasta</h3><Input type="date" value={fSalida} onChange={(e) => {
                    setQuantity(GetNoches(e.target.value))
                }}></Input>
                </Col>}
                {hora&&<Col xs={12} md={{ size: 2}}> <h3>Hora de salida</h3><Input type="select" color value={hora} onChange={(e) => setHora(e.target.value)}>{listaHorarios.map((x,i) => <option key={i}>{x}</option>)}</Input></Col>}
                <Col xs={12} md={{ size: 2 }} style={{alignSelf:'end', cursor:"pointer"}}> 
                    <Popup
                    trigger={<Icon onClick={checkSetHour} color={isMediaEstadia?"teal":"grey"} size='large' name='clock' circular />}
                    content='Media estadia'
                    position='bottom center'
                    />
                </Col>
            </Row>

        </React.Fragment>

    )
}



export default connect(state => ({reserva:state.mainReducer.reserva,listaDeptos: state.mainReducer.listaDeptos, listaReservas: state.mainReducer.listaReservas, refrescarDispoYPrecios: state.mainReducer.refrescarDispoYPrecios, precios: state.mainReducer.precios, isMobile: state.mainReducer.isMobile }), null)(PreciosComponent)

