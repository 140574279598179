import React,{useState} from 'react'
import {connect} from 'react-redux'
//import General from './general'
import Diario from './general/diario'
import Mensual from './general/mensual'
import RangoFechas from './general/rangoFechas'
import Main from './pagos/main'
import { Menu, Segment } from 'semantic-ui-react'
import Reportes from './reportes/reportes'

const Mapa=()=>{
const [mostrarMenu,setMostrarMenu]=useState("pagos")
return(
  <React.Fragment>
     <Segment>
        <Menu pointing secondary >
        {/* <Menu.Item
            name='General'
            active={mostrarMenu === 'general'}
            onClick={()=>{setMostrarMenu("general")}}
        /> */}
        <Menu.Item
            name='Pagos'
            active={mostrarMenu === 'pagos'}
            onClick={()=>{setMostrarMenu("pagos")}}
        />
        <Menu.Item
            name='Reportes'
            active={mostrarMenu === 'reportes'}
            onClick={()=>{setMostrarMenu("reportes")}}
        />
        </Menu>
     {/* {mostrarMenu=="general"&& <><Diario/><Mensual/><RangoFechas/></>} */}
     {mostrarMenu=="pagos"&& <Main/>}
     {mostrarMenu=="reportes"&& <Reportes/>}
    </Segment>
  </React.Fragment>
    
)
}

export default connect(state=>({mapLink:state.mainReducer.mapLink,mapInfo:state.mainReducer.mapInfo,listaDeptos:state.mainReducer.listaDeptos,isMobile:state.mainReducer.isMobile}),null)(Mapa)
