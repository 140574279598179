import React,{useState,useCallback} from 'react'
import moment from 'moment'
import {Input } from 'reactstrap';
import {Statistic,Header,Button } from 'semantic-ui-react'
import LoadingOverlay from 'react-loading-overlay';
import {API} from 'aws-amplify';
import {getMonthStatistics} from "../../../../graphql/queries"
import {connect} from 'react-redux'


const RangoFechas=({tipoPrecio})=>{
const [fechaDesde,setFechaDesde]=useState(moment().format('YYYY-MM-DD'))
const [fechaHasta,setFechaHasta]=useState(moment().format('YYYY-MM-DD'))
const [loading,setLoading]=useState(false)
const [estadisticas,setEstadisticas]=useState([])

const BuscarEstadisticas=useCallback(async ()=>{
    if(moment(fechaHasta).diff(fechaDesde,'minutes')>=0)
    {
        setLoading(true)
        try {
            const response= await API.graphql({
                query: getMonthStatistics,
                variables:{from:fechaDesde,to:fechaHasta,type:tipoPrecio},//
                authMode: 'AMAZON_COGNITO_USER_POOLS'
              })
              const month=JSON.parse(response.data.getMonthStatistics);
              const obj={}
              for (let a of Object.keys(month[0])) {
                  obj[a]=month.reduce((prev,current)=>current[a]+prev,0)
              }
              setEstadisticas(obj)

        } catch (error) {
            if (error?.errors[0].message=="Network Error") { 
                alert('Error de conexion'); 
            }
            else if (error?.errors[0].errorType=="ExecutionTimeout") { 
                alert('Tiempo de espera agotado, por favor acote el rango de fechas'); 
            }
        }

        setLoading(false)
        }
    else{
        alert('La fecha de entrada no puede ser menor a la de salida')
    }

},[fechaHasta,fechaDesde,tipoPrecio])

return(
  <React.Fragment>
    <LoadingOverlay
    active={loading}
    spinner
    text='Actualizando'
    >
     <div className={'d-flex justify-content-center flex-wrap'}>

        <div className={"p-3 align-self-center"}>
            <Header as='h1'>Rango de fechas</Header>
        </div>

      </div>
    <div className={'d-flex justify-content-center flex-wrap'}>
        <div className={"p-3 bd-highlight"}>
            <Input type={"date"} value={fechaDesde} onChange={(e)=>{setFechaDesde(e.target.value)}}/>
        </div>
        <div className={"p-3 bd-highlight"}>
            <Input type={"date"} value={fechaHasta} onChange={(e)=>{setFechaHasta(e.target.value)}}/>
        </div>
        <div className={"p-3 bd-highlight"}>
            <Button onClick={BuscarEstadisticas}>Buscar</Button>
        </div>
    </div>

      <br></br>
      <div className={'d-flex justify-content-center justify-content-around flex-wrap'}>
        {Object.keys(estadisticas).filter(x=>x!='date').map((x,i)=>(
                <div className={"p-3 bd-highlight"} key={i}>
                <Statistic>
                    <Statistic.Label>{x}</Statistic.Label>
                    <Statistic.Value>{estadisticas[x]}</Statistic.Value>
                </Statistic>
                </div>
          
        ))}
        </div>

    </LoadingOverlay>
  </React.Fragment>
    
)
}
export default connect(state=>({tipoPrecio:state.mainReducer.tipoPrecio}),null)(RangoFechas)

