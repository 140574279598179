
import React from 'react'
import { ListaFechas } from '../../../../Funciones/utils'
import ListaDias from './listaDias'
import ListaPrecios from './listaPrecios'

const DateTimelinePicker = ({startDate, endDate,payedDates,unpayedDates,pricePerDate, selectedDates, defaultCurrency}) => {
    const listaFechas = ListaFechas(startDate, endDate);
    
    return (<>
        <ListaDias listaFechas={listaFechas} />
        <ListaPrecios defaultCurrency={defaultCurrency} pricePerDate={pricePerDate} selectedDates={selectedDates} unpayedDates={unpayedDates} payedDates={payedDates} listaFechas={listaFechas} />
    </>)

}

export default DateTimelinePicker