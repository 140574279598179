import React, { useEffect, useMemo, useRef, useState } from "react"
import { Button, Modal, ModalHeader, Input, ModalBody, ModalFooter, InputGroupText,InputGroup,InputGroupAddon,} from 'reactstrap';
import { Table, Label, Checkbox, Icon, Input as InputSemantic, Divider} from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { Collapse } from "react-collapse";
import moment from 'moment'
import { isConsumidorFinal, connectPrinter, consumdorFinalObject, chooseCustomer,naObject } from '../../../Funciones/utils'
import InputSelectLabeled from '../../../components/dormis/extras/inputLabeled'

export const ConfirmarPago = ({pagoParcial, setPagoParcial, newReservation, listaDeptos, hora, isMobile, pagarLuego, onPay, open, setOpen, loading, fechaSalida, noches, descripcion, forma, precio, nombreCuenta, customersList, setForma }) => {
  const { tipoPagos, condicionFrenteAlIva } = useSelector(x => x.mainReducer)
  const togglePrinter = useSelector(x => x.printerReducer.togglePrinter)
  const billSession = useSelector(x => x.billReducer)

  const { printer } = useSelector(x => x.printerReducer)
  const billTypes = useSelector(x => x.billReducer?.billTypes)
  const cuitInput = useRef()
  const [openParcialPayment, setOpenParcialPayment] = useState(false)
  const loadingCuitSearch = billSession.loadingCuitSearch;
  const dispatch = useDispatch();
  const { typeBill, IVACondition, toggleBilling } = billSession.billInfo;
  //Funciones al abrir el CONFIRMAR EL PAGO
  useEffect(() => {
    if (open) {
      setForma("")
      //Pone el default el Confirmar pago
      dispatch({ type: "SET_DEFAULT_BILLING_INFO" })
    }
  }, [open])
  useEffect(() => {
    if (isConsumidorFinal(precio, forma)) {
      dispatch({ type: 'SET_CUSTOMER', payload: consumdorFinalObject() });
    }
    else if (customersList.length > 0) {
      dispatch({ type: 'SET_CUSTOMER', payload: chooseCustomer(customersList) });
    }
  }, [forma, typeBill])

  

  let formas = ["", ...tipoPagos.map(x => x.tipo)]
  if (pagarLuego) {
    formas = [...formas, 'Pago parcial']
  }
  
  const canBill = useMemo(() => {
    return (isConsumidorFinal(precio, forma) || customersList.length != 0)
  }, [precio, forma, customersList])
  const isCuitFound = billSession.customer?.searchedCuit
  const customerBillingListWhitNA = isConsumidorFinal(precio, forma) ? [naObject(), ...customersList] : customersList
  const isFacturaBAndIVAExento = (typeBill == "Factura B") && (IVACondition == "IVA Exento");


  const handleTypeBill = (e) => {
    if (e.target.value == "Factura A") {
      dispatch({ type: 'SET_IVA_CONDITION', payload: "Responsable Inscripto" })
    }
    if (e.target.value == "Factura B") {
      dispatch({ type: 'SET_IVA_CONDITION', payload: "Consumidor Final" })
    }
    if (e.target.value == "Factura T") {
      dispatch({ type: 'SET_IVA_CONDITION', payload: "Consumidor Final" })
    }
    if  (e.target.value == "Factura C") {
      dispatch({ type: 'SET_IVA_CONDITION', payload: "Consumidor Final" })
    }
    
    dispatch({ type: "SET_TYPE_BILL", payload: e.target.value })
    
  }


const handleIvaCondition = (e) => {
     dispatch({ type: 'SET_IVA_CONDITION', payload: e.target.value }) 
    }

 const makeBill = billSession.makeBill
 const setMakeBill = () => {
    dispatch({ type: 'SET_MAKE_BILL', payload: !makeBill })
  }

  const handleParcialPayment = (e) => {
    if(precio <= 0) return
    if(e.target.value > precio) return
    if(e.target.value < 0) return

    setPagoParcial(parseInt(e.target.value))
  }

  const handleParcialPaymentClick = () => {
    if(!openParcialPayment) setPagoParcial(0)
    if(openParcialPayment) setPagoParcial(precio)
    setOpenParcialPayment(!openParcialPayment)
  }  
  return (
    <React.Fragment>

      <Modal style={{ maxWidth: "600px" }} isOpen={open} toggle={() => { setOpen(false) }} >
        <ModalHeader style={{ borderBottom: "none", fontSize: "20px !important" }} toggle={() => { setOpen(false) }}
        >Confirmar Pago
        </ModalHeader>
        <ModalBody>
          <Table definition>
            <Table.Body >
              <Table.Row>
                <Table.Cell>Cuenta de</Table.Cell>
                <Table.Cell>{nombreCuenta}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Componentes</Table.Cell>
                <Table.Cell>{descripcion}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={2}>Noches</Table.Cell>
                <Table.Cell>{noches}</Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Forma pago</Table.Cell>
                <Table.Cell>
                  {formas.find(x => x == forma) != undefined ?
                    formas.length > 0 && <Input id={"selectCondicion"} name="forma" value={forma} onChange={(e) => { setForma(e.target.value) }}
                      type="select">{formas.map((x, i) => (<option key={i}>{x}</option>))}</Input>
                    : "Pago parcial"}
                </Table.Cell>

              </Table.Row>
              <Table.Row>
                <Table.Cell width={2}>Fecha salida</Table.Cell>
                <Table.Cell style={{ fontSize: '20px' }}>{`${moment(fechaSalida).format('dddd DD MMMM')} ${hora}`}</Table.Cell>
              </Table.Row>
              {billSession.toggleBilling && formas.find(x => x == forma) != undefined && <Table.Row>
                <Table.Cell width={2}>Facturación</Table.Cell>
                <Table.Cell style={{ fontSize: '20px' }}>
                  <Checkbox
                    disabled={!isConsumidorFinal(precio, forma) && customersList.length == 0}
                    label={"Facturar"}
                    slider
                    onChange={setMakeBill.bind(this)}
                    checked={makeBill && canBill} />
                  <br></br>
                  {!canBill && <Label style={{ marginTop: "10px" }} color="red" >No hay ningun cliente con dni para facturar</Label>}
                  {canBill && makeBill && <>
                    <InputSelectLabeled
                      label={"Tipo"}
                      input={<Input
                        style={{ marginTop: "10px" }}
                        autocomplete="off"
                        type="select"
                        value={typeBill}
                        onChange={handleTypeBill}
                        name="typeBill" id={"typeBill"}>
                        {

                          billTypes.map((x, i) => (<option key={i} value={x}>{x}</option>))

                        }
                      </Input>
 
                      }
                    />
                    {/* CONDICION FRENTE AL IVA INPUT */}
                    <InputSelectLabeled
                      label={"Condición"}
                      input={
                        <Input
                          style={{ marginTop: "10px" }}
                          autocomplete="off"
                          type="select"
                          value={IVACondition}
                          onChange={handleIvaCondition}
                          name="dni" id={"dni"}>
                          {
                            
                            condicionFrenteAlIva.filter(x=>(x=="Responsable Inscripto"&&typeBill=="Factura A")||(x!="Responsable Inscripto"&& (typeBill=="Factura B" || typeBill=="Factura T" || typeBill=="Factura C")))
                            .map(x => (<option key={x} value={x}>{x}</option>))
                            
                          }
                        </Input>
                      }
                      
                    />
                    {/* Input del CUIT */}
                    {((typeBill == "Factura A") || isFacturaBAndIVAExento) &&
                      <InputSemantic
                        ref={cuitInput}
                        action={{
                          style: {
                            backgroundColor: "#17a2b8",
                          },
                          color: "blue",
                          icon: loadingCuitSearch ? "loading" : 'search',
                          onClick: () => dispatch({ type: "CUIT_INFO", payload: cuitInput.current.inputRef.current.value })

                        }}
                        disabled={loadingCuitSearch}
                        placeholder='CUIT'
                        style={{ fontSize: "14px !important", marginTop: "10px" }}
                        size={"mini"}
                        fluid={true}
                        loading={loadingCuitSearch}
                        onResultSelect={false}

                      />}
                    {/* {typeBill == "Factura A" || typeBill == "Factura B" &&
                     <InputSelectLabeled
                       label={"Condición"}
                       input={
                         <Input
                           style={{ marginTop: "10px" }}
                           autocomplete="off"
                           type="select"
                           value={IVACondition}
                           onChange={(e) => {  dispatch({ type: 'SET_IVA_CONDITION', payload: e.target.value}) }}
                           name="dni" id={"dni"}>
                           {
                             condicionFrenteAlIva.map(x => (<option key={x} value={x}>{x}</option>))
                           }
                         </Input>
                       }
                     />
                     
              } */}
              
                    {/* input de clientes */}
                    {(typeBill != "Factura A") && (!isFacturaBAndIVAExento) && <InputSelectLabeled
                      label={"Cliente"}
                      input={<Input
                        style={{ marginTop: "10px" }}
                        autocomplete="off"
                        type="select"
                        value={billSession.customer?.fullName}
                        onChange={(e) => { dispatch({ type: 'SET_CUSTOMER', payload: customerBillingListWhitNA.find(x => x.fullName == e.target.value) }) }}
                        name="dni" id={"dni"}>
                        {
                          customerBillingListWhitNA.map(x => (<option key={x.fullName} value={x.fullName}>{`${x.fullName} ${x.age ? `(${x.age})` : ""}`}</option>))
                        }
                      </Input>
                      }
                    />}
                    {!loadingCuitSearch && isCuitFound && <InputSelectLabeled
                      label={"Cliente"}
                      input={
                        <Input
                      style={{ marginTop: "10px" }}
                      autocomplete="off"
                      type="select"
                      value={billSession.customer?.fullName}
                      name="dni" id={"dni"}>
                      {

                        [billSession.customer].map(x => (<option key={x.fullName} value={x.fullName}>{`${x.fullName} ${x.age ? `(${x.age})` : ""}`}</option>))

                      }
                    </Input>
                      }
                    />}
                  </>}
                  {/* <Checkbox slider label='Imprimir comprobante' /> */}
                </Table.Cell>
              </Table.Row>}
              {togglePrinter && formas.find(x => x == forma) != undefined && <Table.Row>
                <Table.Cell width={2}>Imprimir</Table.Cell>
                <Table.Cell style={{ fontSize: '20px' }}>
                  {!printer.isConnected &&
                    <>
                      <Label color="red" >Comandera no encontrada</Label>
                      {/* reload button */}
                      <Button color="teal" onClick={() => connectPrinter()}>
                        <Icon name="refresh" size="big" />
                      </Button>

                    </>
                  }
                  {printer.isConnected && <>
                    <Checkbox
                      label={"Imprimir ticket"}
                      slider
                      // disabled={!printer.isConnected}
                      onChange={() => {
                        dispatch({ type: "SET_PRINT_TICKET", payload: !printer.printTicket })
                      }}
                      checked={printer.printTicket} />
                    {newReservation && <br></br>}
                    {newReservation && <Checkbox
                      label={"Imprimir checkin"}
                      slider
                      disabled={!printer.isConnected}
                      onChange={() => {
                        dispatch({ type: "SET_PRINT_CHECKIN", payload: !printer.printCheckin })
                      }}
                      checked={printer.printCheckin} />}
                    {newReservation && <br></br>}
                    {newReservation && <Checkbox
                      label={"Imprimir checkout"}
                      slider
                      disabled={!printer.isConnected}
                      onChange={() => {
                        dispatch({ type: "SET_PRINT_CHECKOUT", payload: !printer.printCheckout })
                      }}
                      checked={printer.printCheckout} />}
                  </>}
                  {/* <Checkbox toggle label='Imprimir comprobante' /> */}
                </Table.Cell>
              </Table.Row>}
              <Table.Row>
              <Table.Cell>
                  <Label size={'big'} ribbon>Parcial</Label>
              </Table.Cell>
              <Table.Cell>
                <Checkbox
                  style={{marginBottom:"10px"}}
                  label={"Pago parcial"}
                  slider
                  onChange={handleParcialPaymentClick.bind(this)}
                  checked={openParcialPayment} />
                <br></br>
                {openParcialPayment&&<InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{fontSize:"19px"}}>$</InputGroupText>
                  </InputGroupAddon> 
                  <Input type="number" pattern="[0-9]*" style={{fontSize:"20px"}} value={pagoParcial} onChange={handleParcialPayment}/>
                </InputGroup>}
              </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Label size={'big'} ribbon>Total</Label>
                </Table.Cell>
                <Table.Cell textAlign={isMobile ? 'center' : 'left'} style={{ fontSize: '24px' }}>${precio}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading} id={"buttonTest"} style={{ float: "right" }} color="grey" size={"lg"} onClick={() => { setOpen(false) }}>Cancelar</Button>
          <Button id={"buttonTest"} style={{ float: "right" }} color="info" size={"lg"}
           disabled={loading || (toggleBilling&&!isCuitFound&&typeBill=="Factura A") || (toggleBilling&&!isCuitFound && typeBill=="Factura B" && IVACondition=="IVA Exento")}
           onClick={() => {
              onPay(forma, makeBill,pagoParcial)
            }
            }>Confirmar</Button>
        </ModalFooter>
      </Modal>

    </React.Fragment>

  )
}

export default ConfirmarPago
