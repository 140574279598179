import React,{useRef,useEffect} from "react"
import {  Input, Form, Header,TextArea,Checkbox,Button, Icon} from 'semantic-ui-react'
import {Col,Container,Row,Input as InputBoostrap} from 'reactstrap'
import { useSelector } from "react-redux"
import ReactTable from 'react-table-6'
import moment from "moment"
import LoadingOverlay from "react-loading-overlay"
import { useDispatch } from "react-redux"
import {printPOS,naObject,isConsumidorFinal} from '../../../Funciones/utils'


//return jsx to render a component that will contain the quantity and description of the item to bill
const Product = ({quantity,description,iva,paymentId,dispatch}) => {
        return (
        <Form.Group>
            <Form.Field width={2}>
                <InputBoostrap  disabled placeholder="Cantidad" value={quantity} onChange={(e)=>dispatch({type:"CHANGE_QUANTITY",payload:{paymentId,quantity:e.target.value}})} style={{fontAlign:"center"}}/>
            </Form.Field>
            <Form.Field width={12}>
                <TextArea placeholder="Descripción" value={description} onChange={(e)=>dispatch({type:"CHANGE_DESCRIPTION",payload:{paymentId,description:e.target.value}})}></TextArea>
            </Form.Field>
            <Form.Field width={2}>
                <InputBoostrap style={{fontAlign:"center"}} disabled placeholder="Iva" value={iva} onChange={(e)=>dispatch({type:"CHANGE_QUANTITY",payload:{paymentId,quantity:e.target.value}})}/>
            </Form.Field>
        </Form.Group>
        )
}

const Facturacion = ()=>{
    const {reserva,modal,condicionFrenteAlIva}= useSelector(x=>x.mainReducer)
    const state = useSelector(x => x.billReducer)
    const loadingBill = useSelector(x => x.billReducer.loadingBill)
    const paymentToBillNow = useSelector(x => x.billReducer.paymentToBillNow)
    const paymentToBill = useSelector(x => x.billReducer.paymentToBill)
    const billList = useSelector(x => x.billReducer.billList)
    const togglePrinter = useSelector(x => x.printerReducer.togglePrinter)
    const billTypes= useSelector(x=>x.billReducer?.billTypes)
    const {printer}= useSelector(x=>x.printerReducer)
    const cuitInput = useRef();
    const dispatch=useDispatch()
    
    const { IVACondition,total } = state.billInfo
    const customerBillingListWhitNA =[naObject(), ...reserva.customersList] 
    const loadingCuitSearch=state.loadingCuitSearch;
    const typeBill = state.billInfo?.typeBill
    const isCuitFound = state.customer?.searchedCuit

    useEffect(()=>{
        if (!modal) {
            dispatch({type:'SET_DEFAULT_BILLING_INFO'});
            
        }
        },[modal])
        
    useEffect(()=>{
        //Si es reserva que cargue las bills hechas sino que inicialize
        if(reserva.reservationId){
            dispatch({type:'RELOAD_RESERVATION_BILL',payload:reserva.reservationId})
        }
        else{
            dispatch({type:'SET_BILL_LIST',payload:[]});
        }
        
    },[reserva.reservationId])
    //set the list of customer 
    useEffect(()=>{
        if (isConsumidorFinal(total, state.billInfo.payCondition)) {
            dispatch({type:"SET_CUSTOMER",payload:customerBillingListWhitNA[0] })

          }else
           {
            dispatch({type:"SET_CUSTOMER",payload:reserva.customersList[0] })

        }
        },[total,state.billInfo.payCondition])

    useEffect(()=>{
        if(paymentToBill && reserva.servicesList?.find(x=>x?.paymentsList?.find(y=>y.paymentId==paymentToBill.paymentId))){
            dispatch({type:'SET_PAYMENTS_LIST',payload:[paymentToBill]});
            }
            return ()=>{
                dispatch({type:'SET_PAYMENTS_LIST',payload:[]});
            }
        },[paymentToBill])

    useEffect(()=>{
        if(paymentToBillNow){
            
            dispatch({type:'SET_PAYMENTS_LIST',payload:[paymentToBillNow]});
            dispatch({type:"MAKE_BILL"})
        }
        return ()=>{
            dispatch({type:'SET_PAYMENT_TO_BILL_NOW',payload:null});
        }
        },[paymentToBillNow])

    const NamesForCondition = () => {
        if (!isConsumidorFinal(total, state.billInfo.payCondition)) {
            return reserva.customersList
        } else {
            return customerBillingListWhitNA
        }
    }
    
    if (reserva.nuevaReserva){
        return <></>
    }

    const handleTypeBill = (e) => {
        if (e.target.value == "Factura A") {
          dispatch({ type: 'SET_IVA_CONDITION', payload: "Responsable Inscripto" })
        }
        if (e.target.value == "Factura B") {
          dispatch({ type: 'SET_IVA_CONDITION', payload: "Consumidor Final" })
        }
        if (e.target.value == "Factura T") {
          dispatch({ type: 'SET_IVA_CONDITION', payload: "Consumidor Final" })
        }
        if  (e.target.value == "Factura C") {
          dispatch({ type: 'SET_IVA_CONDITION', payload: "Consumidor Final" })
        }
        dispatch({ type: "SET_TYPE_BILL", payload: e.target.value })
        
      }


    const paymentListBilled=billList.map(x=>x.listProduct).flat().map(x=>x.paymentId);
    const paymentColumn = [
        {
        Header: 'Fecha',
        accessor: 'date',
        width:60,
        style:{fontSize:"1.1rem"},
        headerStyle:{fontSize:"1.2rem"},
        
        Cell: props =>(moment(props.value).format('DD/MM')),
        filterable: false
            },
        {
        Header: 'Importe',
        accessor: 'amount',
        width:80,
        style:{fontSize:"1.1rem"},
        headerStyle:{fontSize:"1.2rem"},
        
            Cell: props =>`$ ${props.value}`,
        filterable: false
            },
        {
        Header: 'Forma',
        width:100,
        accessor: 'method',
        style:{fontSize:"1.1rem"},
        headerStyle:{fontSize:"1.2rem"},
        
        filterable: false
            },
        {
        Header: 'Facturar',
        width:100,
        style:{fontWeight:"bold",fontSize:"1.2rem"},
        headerStyle:{fontSize:"1.2rem"},
            Cell: props =>(<div style={{cursor:"pointer"}}><Checkbox
                checked={state.paymentsList.find(x=>x.paymentId===props.original.paymentId)?true:false}
                onChange={()=>{
                    if (state.paymentsList.find(x=>x.paymentId===props.original.paymentId)?true:false) {
                         //Remove payment from paymentsList
                         dispatch({type:"SET_PAYMENTS_LIST",payload:state.paymentsList.filter(x=>x.paymentId!==props.original.paymentId)})
                    }
                    else{
                        dispatch({type:"SET_PAYMENTS_LIST",payload:[...state.paymentsList,props.original]})
                    }}
                }
            /></div>),
        filterable: false
        }
        ]
    let billColumn = [
        {
            Header: 'Fecha creada',
            accessor: 'date',
            width:130,
            style:{fontSize:"1.1rem"},
            headerStyle:{fontSize:"1.2rem"},
            Cell: props =>(moment(props.value).format('DD/MM/YY HH:mm')),
            filterable: false
        },
        {
            Header: 'Tipo factura',
            accessor: 'typeBill',
            width:170,
            style:{fontSize:"1.1rem"},
            headerStyle:{fontSize:"1.2rem"},
            filterable: false
        },
        {
            Header: 'Condicion',
            accessor: 'payCondition',
            width:170,
            style:{fontSize:"1.1rem"},
            headerStyle:{fontSize:"1.2rem"},
            filterable: false
        },
        {
            Header: 'Razón social',
            accessor: 'name',
            width:250,
            style:{fontSize:"1.1rem"},
            headerStyle:{fontSize:"1.2rem"},
            filterable: false
        },
        {
            Header: 'Total',
            accessor: 'total',
            width:100,
            style:{fontSize:"1.1rem"},
            headerStyle:{fontSize:"1.2rem"},
            Cell: props =>`$ ${props.original.listProduct.reduce((a,b)=>a+b.price,0)}`,
            filterable: false
        },
        {
            Header: 'Abrir link',
            accessor: 'billLink', 
            style:{fontSize:"1.1rem"},
            headerStyle:{fontSize:"1.2rem"},
            Cell: props =>(<div style={{cursor:"pointer"}}><Icon size={"large"} name="copy outline" onClick={()=>{
                window.open(props.value)
                navigator.clipboard.writeText(props.value)
                .then(() => {
                    //console.log('Async: Copying to clipboard was successful!');
                })
                .catch(err => {
                    console.log('Async: Could not copy text: ', err);
                });
            }}/></div>),
            filterable: false
        },

    ]
    //add printer column
    if(printer.isConnected){
        billColumn.push({
            Header: 'Imprimir',
            accessor: 'billLink',
            style:{fontSize:"1.1rem"},
            headerStyle:{fontSize:"1.2rem"},
            Cell: props =>(<div style={{cursor:"pointer"}}><Icon size={"large"} name="print" onClick={()=>{
                const {dateBill,comprobanteNro,cae,dateExpireCae,listProduct,afipQR,payCondition,typeBill,documentNumber,name,IVACondition}=props.original;
                printPOS({bill:{dateBill},comprobanteNro,cae,dateExpireCae,listProduct,afipQR,payCondition,IVACondition,customer:{documentNumber,name}},typeBill)
            }
            }/></div>),
            filterable: false
        })
    }
    billColumn.push({
        Header: 'Cancelar',
        style:{fontWeight:"bold",fontSize:"1.2rem"},
        headerStyle:{fontSize:"1.2rem"},
         Cell: props =>(!props.original.cancelBillNumber&&!billList.find(x=>x.cancelBillNumber==props.original.comprobanteNro))?
         (<div style={{cursor:"pointer"}}><Icon color='red' onClick={()=>{
           if(window.confirm(`¿Seguro que deseas hacer una nota de crédito por $${props.original.listProduct.reduce((a,b)=>a+b.price,0)}?`))
              {
                dispatch({type:"CANCEL_BILL",payload:props.original.comprobanteNro})
                  
            }}} size='large' style={{marginLeft:"5px"}} name='x'/></div>)
            :
            (<div><Icon color='green' size='large' style={{marginLeft:"5px"}} name='check'/></div>),
        filterable: false
          })

    const spanishReactTable = {
        rowsText:"Filas",
        nextText:"Siguiente",
        pageText:"Página",
        ofText:"de",
        previousText:"Anterior",
        noDataText:'No hay pagos', 
        loadingText:"Cargando..."
    }

    return(
        <LoadingOverlay active={loadingBill} spinner text='Cargando'>
        <Container>
            <Row>
                <Col md={4}>
                    <Header style={{marginBottom:'5px'}} as='h1'>{state.customer.fullName=="N/A"?"Consumidor Final":state.customer.fullName}</Header>
                    {true&&<Header style={{marginBottom:'5px',marginTop:'0px'}} as='h3'>DNI: {state.customer.dni}</Header>}
                    <Header style={{marginBottom:'5px',marginTop:'0px'}} as='h3'>Email: {state.customer.email}</Header>
                    <Checkbox 
                    disabled={!state.customer.email} 
                    slider 
                    checked={state.billInfo.sendEmail}
                     onChange={()=>{
                        dispatch({type:"SET_BILL_INFO",payload:{sendEmail:!state.billInfo.sendEmail}})
                        
                    }} 
                    label='Enviar factura por email' />
                    {togglePrinter&&<Checkbox 
                    label={"Imprimir ticket"}
                    slider 
                    disabled={!printer.isConnected}
                    onChange={()=>{
                        dispatch({type:"SET_PRINT_TICKET",payload:!printer.printTicket})
                    }} 
                    checked={printer.printTicket}/>}
                    {!((typeBill=="Factura A") || (typeBill == "Factura B" && IVACondition == "IVA Exento"))&&<InputBoostrap autocomplete="off"
                            type="select"
                            value={state.customer.fullName}
                            onChange={(e) => { dispatch({ type: 'SET_CUSTOMER', payload: customerBillingListWhitNA.find(x => x.fullName == e.target.value) }) }} 
                            name="dni" id={"dni"}>
                            {
                        NamesForCondition().map(x => (<option key={x.fullName} value={x.fullName}>{`${x.fullName} ${x.age ? `(${x.age})` : ""}`}</option>))
                            }
                    </InputBoostrap>}
                    {!loadingCuitSearch&&state.customer?.searchedCuit&&<InputBoostrap 
              style={{marginTop:"10px"}}
              autocomplete="off"
                            type="select"
                            value={state.customer?.fullName}
                            name="dni" id={"dni"}>
                            {
                            
                            [state.customer].map(x=>(<option key={x.fullName} value={x.fullName}>{`${x.fullName} ${x.age?`(${x.age})`:""}`}</option>))
                            
                            }
              </InputBoostrap>}
                    <br/>
                    <ReactTable 
                    {...spanishReactTable}
                    defaultPageSize={7}
                    className="-striped -highlight" 
                    filterable 
                    showPagination= {false}
                    loading={false} columns={paymentColumn}
                    data={reserva?.servicesList.map(x=>x.paymentsList.map(y=>({...y,serviceId:x.serviceId}))??[]).flat().sort(function(a, b){return moment(b.date).diff(moment(a.date))}).filter(x=>!paymentListBilled.find(y=>y==x.paymentId))} />
                    {/* <Header style={{marginTop:'0px'}} as='h3' > {`${state.customer.dni} - ${state.customer.email}`}</Header> */}
                </Col>

                <Col md={8}>
                    <Row>
                        <Col xs={12} md={4}>
                            <h3>Tipo factura</h3>
                            <InputBoostrap autocomplete="off"
                            type="select"
                            value={typeBill}
                            onChange={ handleTypeBill} 
                            name="dni" id={"dni"}>
                            {billTypes.map(x=>(<option key={x} value={x}>{x}</option>))}
                            </InputBoostrap>
                            
                            {(typeBill=="Factura A"|| typeBill=="Factura B"&&IVACondition=="IVA Exento") &&<Input
                            ref={cuitInput}

                              action={{ 
                                icon: loadingCuitSearch?"loading":'search',
                                color: 'teal',
                                onClick: () => dispatch({type:"CUIT_INFO",payload:cuitInput.current.inputRef.current.value})
                              
                            }} 
                            disabled={loadingCuitSearch}
                            placeholder='CUIT'
                            style={{fontSize:"14px !important",marginTop:"10px"}}
                            size={"large"}
                            fluid={true}
                            loading={loadingCuitSearch}
                            onResultSelect={false}

                            />}

                        </Col>
                        <Col xs={12} md={4}>
                            <h3>Condicion</h3>
                            <InputBoostrap autocomplete="off"
                            type="select"
                            value={IVACondition}
                            onChange={(e) => dispatch({type: 'SET_IVA_CONDITION', payload: e.target.value })} 
                            name="dni" id={"dni"}>
                            {condicionFrenteAlIva.map(x => (<option key={x} value={x}>{x}</option>))}
                            </InputBoostrap>
                        </Col>
                        <Col xs={12} md={4}>
                            <h3>Forma de pago</h3>
                            <InputBoostrap autocomplete="off"
                            type="select"
                            value={state.billInfo.payCondition}
                            onChange={(e) => dispatch({type:"SET_BILL_INFO",payload:{payCondition:e.target.value}})} 
                            name="dni" id={"dni"}>
                            {state.payConditions.map(x=>(<option key={x} value={x}>{x}</option>))}
                            </InputBoostrap>
                        </Col>
                        <Col xs={12} md={4}>
                            <h3>Fecha emisión</h3>
                            <Input autocomplete="off"
                            value={state.billInfo.dateBill}
                            onChange={(e) => dispatch({type:"SET_BILL_INFO",payload:{dateBill:e.target.value}})} 
                            type="date"
                            name="dni" id={"dni"}></Input>
                        </Col>
                    </Row>
                     <Row>
                        <Col xs={12} md={4}>
                            <h3>Facturado desde</h3>
                            <Input autocomplete="off"
                            value={state.billInfo.dateFrom}
                            onChange={(e) =>
                                {
                                    //Check if from is before to and if not alert
                                    if (moment(e.target.value).isAfter(state.billInfo.dateTo)) {
                                        alert("La fecha desde no puede ser mayor a la fecha hasta")
                                    }
                                    else{
                                        dispatch({type:"SET_BILL_INFO",payload:{dateFrom:e.target.value}})}
                                    }
                            }
                            type="date"
                            name="dni" id={"dni"}></Input>
                        </Col>
                        <Col xs={12} md={4}>
                            <h3>Facturado hasta</h3>
                            <Input autocomplete="off"
                            value={state.billInfo.dateTo}
                            onChange={(e) => {
                                //Check if from is before to and if not alert
                                if (moment(e.target.value).isBefore(moment(state.billInfo.dateFrom))) {
                                    alert("La fecha hasta debe ser mayor a la fecha desde")
                                }
                                else{
                                    dispatch({type:"SET_BILL_INFO",payload:{dateTo:e.target.value}})}

                                }
                            }
                            type="date"
                            name="dni" id={"dni"}></Input>
                        </Col>
                        <Col xs={12} md={4}>
                        </Col>
                    </Row> 

                    <Row>
                        <Col xs={12} style={{marginTop:'1em'}}>
                            <Form >
                                <h3 >Items</h3>
                                {state.itemsList.map((item,index)=>(<Product key={index} paymentId={item.paymentId} quantity={item.quantity} iva={item.iva} description={item.description} dispatch={dispatch}/>))}
                              
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        {/* <Col xs={12}>
                            <Header as={'h1'} style={{marginBottom:'5px'}}>Importe Total</Header>
                            <Header as={'h1'} style={{marginTop:'5px'}}>$1000</Header>
                        </Col> */}
                        {/*Abajo se va a declarar un boton para pagar factura*/ }
                        <Col xs={12}>
                            <Button 
                            disabled={state.billInfo.total==0||(!isCuitFound&&typeBill=="Factura A")||(!isCuitFound&&typeBill=="Factura B"&&IVACondition=="IVA Exento")}
                            fluid={true}
                            color={'teal'}
                            size={'big'}
                            onClick={()=>{
                                dispatch({type:"MAKE_BILL"})
                            }}>Facturar ${state.billInfo?.total}</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br/>
            <br/>
                <Row>
                        <Col>
                            <ReactTable
                            {...spanishReactTable}
                            getTrProps={(state, rowInfo) => {  
                            if (rowInfo) {
                                return {
                                style: {
                                    textDecoration: billList.find(x=>x.cancelBillNumber==rowInfo.original.comprobanteNro)? 'line-through' : ''
                                        }
                                    }
                                }
                            else{
                                return{}
                                }
                            }} 
                            defaultPageSize={7}
                            className="-striped -highlight" 
                            filterable 
                            showPagination= {true}
                            loading={false} columns={billColumn}
                            data={billList.sort(function(a, b){return moment(b.date).diff(moment(a.date))})} />
                            
                        </Col>
                    </Row>
        </Container>
        </LoadingOverlay>

    )
}

export default Facturacion;
