import React,{useEffect, useState} from 'react';
import ModalCaja from './modalCaja'
import ReactTable from 'react-table-6'
import {Button,Icon,Popup,Header} from 'semantic-ui-react'
import { useSelector,useDispatch } from 'react-redux'
import {MovimientosCaja,ActualizarFromTo,ObtenerListaPagosFechas,CapitalFirst} from '../../../Funciones/utils'
import {Col,Row} from 'reactstrap';
import moment from 'moment'
import LoadingOverlay from "react-loading-overlay";
import TablaTotales from '../../../components/dormis/estadisticas/pagos/tablaTotales';


const Cell=(props)=>{
    const dispatch=useDispatch();
    return(
    <div style={{cursor:props.original.reservationId?"pointer":"", textDecoration:props.original.canceled?'line-through':""}} onClick={()=>{
        if (props.original.reservationId) {
            dispatch({type:'OPEN_RESERVATION',payload:props.original.reservationId})
        }
    }}>{props.value}</div>
)}

const Caja=()=>{
    const isMobile=useSelector(x=>x.mainReducer.isMobile)
    const openCash=useSelector(x=>x.mainReducer.openCash)
    const cashMovements=useSelector(x=>x.mainReducer.cashMovements)
    const lastBuildNumber=useSelector(x=>x.mainReducer.lastBuildNumber)
    const isAdmin=useSelector(x=>x.mainReducer.isAdmin)
    const isCashClose=useSelector(x=>x.mainReducer.isCashClose)
    const isCashAdmin=useSelector(x=>x.mainReducer.isCashAdmin)
    const paymentList=useSelector(x=>x.mainReducer.paymentList)
    const cashFromTo=useSelector(x=>x.mainReducer.cashFromTo)
    const currentBuildNumber=useSelector(x=>x.mainReducer.currentBuildNumber)
    const cashMovementLoading=useSelector(x=>x.mainReducer.cashMovementLoading)
   
    const [listaMovimientos,setListaMovimientos]=useState([])
    const [openModal,setOpenModal]=useState(false)
    const [isRetiro,setIsRetiro]=useState(true)
    const [closeCash,setCloseCash]=useState(false)
    const dispatch = useDispatch()
    //Cuando cambia la lista de cashmovement hay que actualizar la lista de pagos y eso se hace actualizando el from y el to
    useEffect(()=>{
        const paymentFunction=async ()=>{
          if (moment(cashFromTo.to).diff(cashFromTo.from,'seconds')>0) {
            const pagos=await ObtenerListaPagosFechas(moment(cashFromTo.from).format(),moment(cashFromTo.to).format())
            dispatch({type:'SET_PAYMENT_LIST',payload:pagos})
          }
        }
        paymentFunction();
    },[cashFromTo])

    useEffect(()=>{
        ActualizarFromTo(dispatch)
    },[cashMovements])

    useEffect(()=>{
        setListaMovimientos(MovimientosCaja())
    },[openCash,cashMovements,lastBuildNumber,paymentList])

    var columns = [
        {
        Header: 'Hora',
        accessor: 'fecha',
        filterable: false,
        sortable:false,
        Cell
        },
        {
        Header: 'Concepto',
        accessor: 'concepto',
        filterable: false,
        sortable:false,
        Cell
        },
        {
        Header: 'Tipo',
        accessor: 'tipo',
        filterable: false,
        sortable:false,
        Cell
        },
      ]
      if (isAdmin) {
          columns=[ ...columns,
            {
                Header: 'Entrada',
                accessor: 'entrada',
                filterable: false,
                sortable:false,
                Cell
                },
                {
                Header: 'Salida',
                accessor: 'salida',
                filterable: false,
                sortable:false,
                Cell
                },
            {
            Header: 'Usuario',
            accessor: 'usuario',
            filterable: false,
            sortable:false,
            Cell
            }]
      }
      
      const fechaIncio=cashFromTo.from=='3020-01-19'?'-':moment(cashFromTo.from).format('DD/MM HH:mm')
      const fechaCierre=cashFromTo.to=='3020-01-20'?'-':moment(cashFromTo.to).format('DD/MM HH:mm')
      const fechaCaja=cashFromTo.to=='3020-01-20'?moment().format('dddd DD MMMM'):moment(cashFromTo.to).format('dddd DD MMMM')
    return(
        <>
        
        <LoadingOverlay
        active={cashMovementLoading}
        spinner
        text='Agregando'
        >
                    <Button.Group vertical={isMobile} style={{margin: "auto"}} widths={'3'}>
                        <Popup content='Ingreso a la caja' position='top center'
                            trigger={
                            <Button disabled={isCashClose} size={isMobile?'massive':'big'} color='green' fluid onClick={()=>{
                                setIsRetiro(false);setOpenModal(true);setCloseCash(false);
                            }}>Ingresar dinero</Button>
                        } />


                        {!isMobile&&<Button.Or text={"o"}/>}

                        <Popup content='Retirar de la caja' position='top center'
                            trigger={
                            <Button disabled={isCashClose} size={isMobile?'massive':'big'} color='blue' fluid onClick={()=>{                    
                                setIsRetiro(true);setOpenModal(true);setCloseCash(false);
                            }}>Retirar dinero</Button>
                        } />

                        {!isMobile&&isCashAdmin&&<Button.Or text={"o"}/>}
                        {isCashAdmin&&<Button  size={isMobile?'massive':'big'} color={isCashClose?'green':'grey'} fluid onClick={()=>{
                                setOpenModal(true);
                                setCloseCash(true);
                                }}>{isCashClose?'Abrir caja':'Cerrar caja'}</Button>}

                        </Button.Group>
                        <Row>
                            <Col xs={6} md={2}>
                                <h2>
                                Fecha de inicio
                                </h2>
                                <h3>
                                {fechaIncio}
                                </h3>
                            </Col>
                            <Col xs={6} md={2}>
                                <h2>
                                Fecha de cierre
                                </h2>
                                <h3>
                                {fechaCierre}
                                </h3>
                            </Col>
                        </Row>
                        <div className={'d-flex justify-content-center flex-wrap'}>
                                <div className={"p-3 bd-highlight"}>
                                <Icon disabled={currentBuildNumber==0||!isAdmin} style={{cursor:'pointer'}} name="arrow left" size={'huge'} onClick={()=>{dispatch({type:'SET_CURRENT_BUILD_NUMBER',payload:currentBuildNumber-1})}}/>
                                </div>
                                <div className={"p-12 align-self-center"}>
                                    <Header as='h1'>{CapitalFirst(fechaCaja)}</Header>
                                </div>
                                <div className={"p-3 bd-highlight"}>
                                <Icon disabled={currentBuildNumber==lastBuildNumber} style={{cursor:'pointer'}} name="arrow right" size={'huge'} onClick={()=>{dispatch({type:'SET_CURRENT_BUILD_NUMBER',payload:currentBuildNumber+1})}}/>
                                </div>
                            </div>
        <ModalCaja closeCash={closeCash} isRetiro={isRetiro} openCash={closeCash&&isCashClose} open={openModal} setOpen={setOpenModal} titulo={"Retirar dinero"}/>
            {
                isAdmin&&paymentList&&<>
                <TablaTotales isCaja={true} paymentList={paymentList.filter(x=>!x.canceled)}/>
                
            </>}
    <br></br>
        <ReactTable 
        rowsText={"Filas"}
        nextText={"Siguiente"}
        pageText={"Página"}
        ofText={"de"}
        previousText={"Anterior"}
        noDataText={'No se encontraron movimientos en la caja'} 
        className="-striped -highlight" 
        loading={false} 
        columns={columns} data={listaMovimientos}></ReactTable>
        
        </LoadingOverlay>
        </>
    )
}

export default Caja;
