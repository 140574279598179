import React from 'react'
import ReactTable from 'react-table-6'
import moment from 'moment'
import printJS from 'print-js'
import {Row,Col} from 'reactstrap'
import { Button } from 'semantic-ui-react'
import {useSelector,useDispatch} from 'react-redux'

const PrintFunc=(lista)=>{
    printJS({gridStyle: 'text-align:center;border: 1px solid #d7d7d7;',printable: lista, properties: ['Nombre y Apellido', 'Procedencia','DNI/PASAP', 'Edad', 'Vehiculos','Fecha De Ingreso','Fecha De Egreso'], type: 'json'})
}

const Imprimir=()=>{

  const {listaCheckout,pageList,listingLoading} = useSelector(x => x.mainReducer)
  const dispatch = useDispatch(x=>x)
  const mappedLis=listaCheckout.sort((a, b) =>moment(a.checkoutMade).diff(moment(b.checkoutMade))).map(x=>x.customersList.map(y=>({
    'Nombre y Apellido':`${y.fullName}`,
    'DNI/PASAP':y.dni==null?'-':y.dni,
    'Edad':(y.birthdate&&moment().diff(y.birthdate,'years')!=0)?moment().diff(y.birthdate,'years'):'-',
    'Procedencia':y.geo?JSON.parse(y.geo).value:"-",
    'Vehiculos':x.vehiclesList.length>0?x.vehiclesList.map(y=>(`${y.brand?y.brand.toUpperCase():""} ${y.plate}`)).toString().replace(/,/g,' | '):"-",
    'Fecha De Ingreso':moment(x.checkinMade).format("DD MMM YYYY HH:mm"),
    'Fecha De Egreso':moment(x.checkoutMade).format("DD MMM YYYY HH:mm"),
  }))).flat()
    var columns = [
        {
        Header: 'Nombre y Apellido',
        accessor: 'Nombre y Apellido',
        sortable:false,
        filterable: false
        },
        {
            Header: 'DNI/PASAP',
            accessor: 'DNI/PASAP',
            sortable:false,
            filterable: false
        },
        {
            Header: 'Procedencia',
            accessor: 'Procedencia',
            sortable:false,
            filterable: false
        },
        {
            Header: 'Edad',
            accessor: 'Edad',
            sortable:false,
            filterable: false
        },
        {
            Header: 'Vehiculos',
            accessor: 'Vehiculos',
            sortable:false,
            filterable: false
        },
        {
            Header: 'Fecha De Ingreso',
            accessor: 'Fecha De Ingreso',
            sortable:false,
            filterable: false
        },
        
        {
            Header: 'Fecha De Egreso',
            accessor: 'Fecha De Egreso',
            sortable:false,
            filterable: false
        },
        ]
   
    return(
      <React.Fragment>
        <br></br>
        <Row>
            <Col xs={1}>
                <Button basic color='blue' onClick={()=>{PrintFunc(mappedLis)}}>
                    Imprimir
                </Button>   
            </Col>
        </Row>
        <ReactTable 
        manual
        rowsText={"Filas"}
        nextText={"Siguiente"}
        pageText={"Página"}
        ofText={"de"}
        previousText={"Anterior"}
        loading={listingLoading}
        noDataText={'No se encontraron registros'} 
        className="-striped -highlight" filterable loadingText={"Cargando..."} 
        onPageChange= {(e)=>dispatch({type:'PAGE_LISTINGS',payload:e})}
        page={pageList}
        pages={9999}
        columns={columns} data={mappedLis}></ReactTable>
    </React.Fragment>
    )
  }

export default Imprimir

