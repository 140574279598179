import React from 'react';
import { InputGroup, InputGroupText } from 'reactstrap';


const InputSelectLabeled = ({
    label,
    input }) => {
    return (
        <InputGroup>
            <InputGroupText style={{ marginTop: "10px", flexBasis: "6em",justifyContent:"center"}}>
                {label}
            </InputGroupText>
            {input}
        </InputGroup>
    );
};

export default InputSelectLabeled;