
import React,{useState, useEffect} from "react"
import {Modal, ModalHeader, ModalBody, Container,Col,Row} from 'reactstrap'
import { Header, Divider } from "semantic-ui-react"
import {useSelector, useDispatch} from 'react-redux'
import moment from 'moment'

export const ModalBanEncontrado = () => {
  const isMobile = useSelector(state => state.mainReducer.isMobile)
  const foundBanModal = useSelector(state => state.banReducer.foundBanModal)
  const banFound = useSelector(state => state.banReducer.banFound)

  const dispatch = useDispatch()

  const closeModal = () => {
    if(window.confirm("¿Está seguro que desea cerrar la advertencia?")){
      dispatch({type:"SET_BAN_STATUS",payload:{foundBanModal:false}})
    }
  }

const {datetime, reason, customer, campingName} = banFound
if(!customer) return null
return (
<Modal style={{ maxWidth: "600px" }} size="lg" isOpen={foundBanModal} toggle={closeModal}>
        <ModalHeader style={{borderBottom:"none",marginTop:"10px"}} toggle={closeModal}>
          <Header as="h2">Alerta de ingreso prohibido</Header>
          <Divider />
        </ModalHeader>
   <ModalBody>
      <Container>
        <Row>
          <Col xs={12}>
            <p>
              El cliente <b>{customer?.fullName}</b> tiene prohibido el ingreso desde el <b>{moment(datetime).format("DD MMM hh:mm a")}</b>.
            </p>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col xs={12}>
            <p>
            Razon: <b>{reason}</b>
            </p>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col xs={12}>
            <p>
              Prohíbido por: <b>{campingName}</b>
            </p>
          </Col>
        </Row>
      </Container>
    </ModalBody>
  </Modal>
);
}



export default ModalBanEncontrado
