import React,{useState, useEffect} from "react"
import LoadingOverlay from 'react-loading-overlay'
import {Modal, ModalHeader, ModalBody} from 'reactstrap'
import { Header, Checkbox, Button, TextArea, Form, Divider } from "semantic-ui-react"
import ReactTable from "react-table-6"
import {useSelector, useDispatch} from 'react-redux'
import { v4 as uuidv4 } from 'uuid';


export const ModalCrearBan = () => {
  const isMobile = useSelector(state => state.mainReducer.isMobile)
  const reservationId = useSelector(state => state.banReducer.reservationId)
  const customersList = useSelector(state => state.banReducer.customersList)
  const openCreateBanModal = useSelector(state => state.banReducer.openCreateBanModal)
  const loading = useSelector(state => state.banReducer.loading)

  const dispatch = useDispatch()
  const [state,setState] = useState({
    banReason:"",
    customersList:customersList || []
  })

  useEffect(()=>{
    setState({...state,customersList:customersList})
  },[customersList])


  const spanishReactTable = {
    rowsText:"Filas",
    nextText:"Siguiente",
    pageText:"Página",
    ofText:"de",
    previousText:"Anterior",
    noDataText:'No hay pagos', 
    loadingText:"Cargando..."
}

const banedColumns = [
  {
    Header: "Nombre",
    accessor: "fullName",
    width:220
  },
  {
    Header: "DNI",
    accessor: "dni",
  },
  {
    Header: "Teléfono",
    accessor: "phone",
  },
  {
    Header: "Seleccionar",
    width:100,
    accessor: "customerId",
    Cell: (props) => (
      <div>
        <Checkbox
          checked={state.customersList.map(x=>x.customerId).includes(props.value)}
          onChange={(e, { checked }) => {
            if(checked){
              setState({...state,customersList:[...state.customersList,props.original]})
            }else{
              setState({...state,customersList:state.customersList.filter(x=>x.customerId!==props.value)})
            }
          }}
        />
      </div>
    ),
  },
]

  const closeModal = () => {
    dispatch({type:"SET_BAN_STATUS",payload:{openCreateBanModal:false}})
  }

  const createBan=()=>{
    const banInput={
      reservationId,
      banId:uuidv4(),
      customersList:state.customersList,
      reason:state.banReason
    }
    dispatch({type:"CREATE_BAN",payload:banInput})
  }

  const error= !reservationId?"Debe crear el registro primero":!state.banReason?"No se ha ingresado una razón":!state.customersList.length?"No se ha seleccionado ninguna persona":false
  return (
    <Modal style={{maxWidth:"600px"}} size={"lg"} isOpen={openCreateBanModal} toggle={closeModal}>
    <LoadingOverlay
    active={loading}
    spinner
    text='Agregando prohibición...'
    >
        <ModalHeader style={{borderBottom:"none",marginTop:"10px"}} toggle={closeModal}>
          <Header as="h2">Prohibir ingreso </Header>
          <Divider />

        </ModalHeader>
        <ModalBody>        
        <Header as="h3" style={{marginBottom:"8px"}}>Seleccionar personas</Header>    
          <ReactTable
            {...spanishReactTable}
            showPageJump={false}
            showPageSizeOptions={false}
            data={customersList}
            columns={banedColumns}
            defaultPageSize={5}
            className="-striped -highlight"
          />

         <Header as="h3" style={{marginBottom:"8px"}}>Razón</Header>

         <Form>
          <TextArea style={{width:"100%",height:"100px"}} onChange={(e,{value})=>setState({...state,banReason:value})} />
         </Form>
          <Button disabled={error} onClick={createBan} style={{width:"68%", margin:"auto",marginTop:"10px"}}  size={isMobile?'massive':'big'} color='teal' fluid >Prohibir ingreso</Button>
          {error && <Header as="h4" style={{color:"red", textAlign:"center",marginTop:"5px"}}>{error}</Header>}
          </ModalBody>
    </LoadingOverlay>
  </Modal>
   
    )
}



export default ModalCrearBan
