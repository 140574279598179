import React,{useState,useEffect,useMemo} from 'react'
import moment from 'moment'
import {Col,Row } from 'reactstrap';
import {CapitalFirst} from '../../../../Funciones/utils'
import { Divider, Header,Segment,Input,Icon } from 'semantic-ui-react'
import RangoFechas from './rangoFechas'
import TablaListaPagos from './tablaListaPagos'
import { Collapse } from 'react-collapse';
import { useSelector,useDispatch } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import TablaTotales from './tablaTotales';

const Estadisticas=()=>{
const [fecha,setFecha]=useState(moment().format('YYYY-MM-DD'))
const [abrirComponentes,setAbrirComponentes]=useState(false)

const paymentListStatistics=useSelector(x=>x.mainReducer.paymentListStatistics)
const paymentstatisticsloading=useSelector(x=>x.mainReducer.paymentstatisticsloading)
const isMobile=useSelector(x=>x.mainReducer.isMobile)

const dispatch=useDispatch();
useEffect(() => {
  dispatch({type:'SET_PAYMENT_LIST_DAY',payload:fecha})
}, [fecha])
const estadisticasGrafico=useMemo(()=>paymentListStatistics.filter(x=>!x.canceled),[paymentListStatistics])

return(
  <React.Fragment>
    <LoadingOverlay
    active={paymentstatisticsloading}
    spinner
    text='Actualizando'
    >
      <Segment>
      <Row>
        <Col xs={2}>
          <Input value={fecha} type='date' onChange={(e)=>{setFecha(e.target.value)}}></Input>
        </Col> 
      </Row>
      <div className={'d-flex justify-content-center flex-wrap'}>
        <div className={"p-3 bd-highlight"}>
            <Icon style={{cursor:'pointer'}} name="arrow left" size={'huge'} onClick={()=>{setFecha(moment(fecha).add(-1,'days').format('YYYY-MM-DD'))}}/>
        </div>
        <div className={"p-12 align-self-center"}>
            <Header as='h1'>{CapitalFirst(moment(fecha).format('dddd DD MMMM'))}</Header>
        </div>
        <div className={"p-3 bd-highlight"}>
            <Icon style={{cursor:'pointer'}} name="arrow right" size={'huge'} onClick={()=>{setFecha(moment(fecha).add(1,'days').format('YYYY-MM-DD'))}}/>
        </div>
      </div>
      <Collapse isOpened={abrirComponentes}>
        <RangoFechas/>
      </Collapse>
      {abrirComponentes && <Divider horizontal><Icon onClick={() => { setAbrirComponentes(!abrirComponentes) }} style={{ fontSize: isMobile ? '2.7rem' : '1.8rem', cursor: 'pointer' }} name='arrow circle up' size='small' /></Divider>}
      {!abrirComponentes && <Divider horizontal><Icon onClick={() => { setAbrirComponentes(!abrirComponentes) }} style={{ fontSize: isMobile ? '2.7rem' : '1.8rem', cursor: 'pointer' }} name='arrow circle down' size='small' /></Divider>}
        <TablaTotales isCaja={false} paymentList={estadisticasGrafico}/>
        <TablaListaPagos/>
      </Segment>

      <Divider horizontal>
        <Header as='h4'>
          .
        </Header>
      </Divider>
    </LoadingOverlay>
  </React.Fragment>
    
)
}

export default Estadisticas
