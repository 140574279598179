import React,{useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Menu,Icon,Search, Select} from 'semantic-ui-react'
import {CapitalFirst} from '../../../Funciones/utils'

const ChangeTypeSelect=()=>{
    const precios = useSelector(x=>x.mainReducer.precios)
    const tipoPrecio = useSelector(x=>x.mainReducer.tipoPrecio)
    const isMobile = useSelector(x=>x.mainReducer.isMobile)
    const dispatch=useDispatch();
    const priceFound = precios.find(x=>x.type == tipoPrecio)
    const onChangeType = (e, { value }) => {
      dispatch({type:'CHANGE_PRICE_TYPE',payload: value})
    }
    if(!priceFound.mergeRoomType) return null
    return <Select onChange={onChangeType} style={{marginLeft:!isMobile?"10px":"",fontSize:"12px"}} value={tipoPrecio} placeholder='Tipo' options={precios.map(x=>({ key: x.type, value: x.type, text: CapitalFirst(x.type) }))} />
}

export default ChangeTypeSelect