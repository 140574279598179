import React, { useMemo } from "react"
import moment from 'moment'
import {Col,Row,Modal, ModalHeader, ModalBody, } from 'reactstrap';
import {CapitalFirst, InformacionReservas, printCleaning, printCleaningInAndOut} from "../../../Funciones/utils"
import {connect} from 'react-redux'
import GetCard from './getCard'
import { Card,Header, Segment,Icon } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
//ACTIONS
import setFecha from '../../../redux/actions/displayFecha'

export const DisplayFecha = ({fecha,informacionReservas,dispatch,mostrarFull}) => {
  const { printer } = useSelector(x => x.printerReducer)
    
   if(!fecha){
     return null;
   }
   return (
      <Row>
        <Col xs={12} md={4}>     
          <Header style={{cursor:'pointer'}} onClick={()=>dispatch(setFecha(moment().format()))} as='h2' icon textAlign='center' attached='top'>
            <Icon name='sign-in' color={"blue"} circular />
            <Header.Content>
              <div style={{display:"inline-flex",gap:"10px"}}>
                Entradas ({informacionReservas.entran.map(x=>x.roomsList).flat().length}) 
                {printer?.isConnected && 
                    <Icon style={{fontSize:"20px", cursor:"pointer", marginTop:"5px"}} 
                          size="tiny" 
                          onClick={()=>printCleaning({label:"Entradas",listado:informacionReservas.entran, date:fecha})} 
                          name="clipboard outline" >
                    </Icon>
                }
              </div>
            </Header.Content>               
            </Header>
          {mostrarFull&&
          <Segment attached>
            <Card.Group>

          {informacionReservas.entran.map((x,i)=>(

                 <GetCard ingreso={x} i={i}></GetCard>
          ))}
          
        
           </Card.Group>
          </Segment>}
        </Col>

      <Col xs={12} md={4}>  
      <Header onClick={()=>dispatch(setFecha(moment().format()))} as='h2' icon textAlign='center' attached='top'>
            <Icon name='home' color={"blue"} circular />
            <Header.Content>
              <div style={{display:"inline-flex",gap:"10px"}}>
                Estancias ({informacionReservas.estancias.map(x=>x.roomsList).flat().length}) 
                {printer?.isConnected && 
                    <Icon style={{fontSize:"20px", cursor:"pointer", marginTop:"5px"}} 
                          size="tiny" 
                          onClick={()=>printCleaning({label:"Estancias",listado:informacionReservas.estancias, date:fecha})} 
                          name="clipboard outline" >
                    </Icon>
                }
              </div>
            </Header.Content>
      </Header>
        {mostrarFull&&
          <Segment attached>
          <Card.Group>
          {informacionReservas.estancias.map((x,i)=>(
                  <GetCard ingreso={x} i={i}></GetCard>

          ))}
        </Card.Group>
      </Segment>}
      </Col>

      <Col xs={12} md={4}>  
      <Header style={{cursor:'pointer'}} onClick={()=>dispatch(setFecha(moment().format()))} as='h2' icon textAlign='center' attached='top'>
            <Icon name='sign-out' color={"blue"} circular />
            <Header.Content>
              <div style={{display:"inline-flex",gap:"10px"}}>
                Salidas ({informacionReservas.salen.map(x=>x.roomsList).flat().length}) 
                {printer?.isConnected && 
                    <Icon style={{fontSize:"20px", cursor:"pointer", marginTop:"5px"}} 
                          size="tiny" 
                          onClick={()=>printCleaning({label:"Salidas",listado:informacionReservas.salen, date:fecha})} 
                          name="clipboard outline" >
                    </Icon>
                }
              </div>
            </Header.Content>         
        </Header>
        {mostrarFull&&
          <Segment attached>
          <Card.Group>
          {informacionReservas.salen.map((x,i)=>(
            <GetCard ingreso={x} i={i}></GetCard>
          ))}
        </Card.Group>
      </Segment>}
      </Col>
     </Row>

   
    )
}

const ModalDisplayFecha=({fecha,listaReservas,dispatch,mostrarFull})=>{
  const { printer } = useSelector(x => x.printerReducer)
  const informacionReservasAux=useMemo(() => InformacionReservas(listaReservas,fecha), [fecha]) 
  const informacionReservas=useMemo(() =>fecha?{entran:informacionReservasAux.entran.filter(x=>x.state!="cancelada"),
                             estancias:informacionReservasAux.estancias.filter(x=>x.state!="cancelada"),
                             salen:informacionReservasAux.salen.filter(x=>x.state!="cancelada")}:[], [fecha])
  return(
  <Modal style={{maxWidth:"1000px"}} size={"lg"} isOpen={fecha!=null} toggle={()=>{dispatch(setFecha(null));}} >
    <ModalHeader style={{borderBottom:"none"}} toggle={()=>{dispatch(setFecha(null));}}>
    <div style={{display:"flex",justifyContent:"space-between",alignItems:"center", gap:"1.5rem"}}>
      <Header as='h2'>{CapitalFirst(moment(fecha).format("dddd DD/MM/YYYY"))}</Header>
      {printer?.isConnected && <Icon size="large" onClick={()=>printCleaningInAndOut({...informacionReservas, date:fecha})} style={{cursor:"pointer",marginBottom:"14px"}} name="clipboard outline" ></Icon>}
    </div>
  </ModalHeader>
  <ModalBody>
    <DisplayFecha fecha={fecha} informacionReservas={informacionReservas} dispatch={dispatch} mostrarFull={mostrarFull}/>
</ModalBody>
</Modal>)
}

export default connect(state=>({listaReservas:state.mainReducer.listaReservas}),null)(ModalDisplayFecha)

