import React,{useState,useEffect} from "react"
import { Button,Col,Row,Input,InputGroupText,InputGroup,InputGroupAddon,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { Radio,Header } from 'semantic-ui-react'
import { useDispatch,useSelector } from "react-redux";
import { Fragment } from "react";
import {CapitalFirst,TransformToLocalCurrency, printCashWithdraw} from '../../../Funciones/utils'


export const ModalCaja = ({isRetiro,openCash,closeCash,open,setOpen}) => {
const [descripcion,setDescripcion]=useState('')
const [tipo,setTipo]=useState('')
const [print,setPrint]=useState(true)
const [precio,setPrecio]=useState(0)
const [saldo,setSaldo]=useState({})
const dispatch = useDispatch()
const cashMovementLoading = useSelector(x=>x.mainReducer.cashMovementLoading)
const isCashClose = useSelector(x=>x.mainReducer.isCashClose)
const tipoPagos = useSelector(x=>x.mainReducer.tipoPagos)
const isAdmin = useSelector(x=>x.mainReducer.isAdmin)
const { printer } = useSelector(x => x.printerReducer)

useEffect(() => {
  if(tipoPagos.length>0){
    setTipo(tipoPagos[0].tipo)
  }
}, [open])
useEffect(() => {
  const newSaldo=[...tipoPagos].slice(2).map(x=>({[x.tipo]:0})).reduce((acc,curr)=>({...acc,...curr}),{})
  setSaldo(newSaldo);
}, [tipoPagos])

const initAll=()=>{
    const newSaldo=[...tipoPagos].slice(2).map(x=>({[x.tipo]:0})).reduce((acc,curr)=>({...acc,...curr}),{})
    setDescripcion('')
    setPrecio(0);
    setOpen(false);
    setSaldo(newSaldo);

}
return (
    <React.Fragment>

      <Modal style={{maxWidth:"600px"}} isOpen={open} toggle={()=>{initAll()}} >

          <ModalHeader style={{borderBottom:"none",fontSize:"20px !important"}} toggle={()=>{initAll()}}>
            {closeCash?isCashClose?`Abrir caja`:`Cerrar caja`:isRetiro?'Retirar dinero de la caja':'Ingresar dinero a la caja'}
          </ModalHeader>
          <ModalBody>
          <Row>

          {!isCashClose&&[...tipoPagos].map((x,i)=>
            <Fragment key={i}>
               <Col xs={6} md={4} style={{marginTop:'7px'}}>
                <Header as='h3' style={{marginBottom:"5px",marginTop:'10px'}}>{CapitalFirst(x.tipo.split('Transferencia').length>1?x.tipo.split(' ')[0]:x.tipo)}</Header>
                {isAdmin&&closeCash&&window.totales&&<Header as='h4' style={{margin:"0px"}}>{TransformToLocalCurrency(window.totales.find(y=>x.tipo==y.tipo)?.valor,2)}</Header>}
                {!closeCash&&<Radio onChange={()=>setTipo(x.tipo)} checked={tipo==x.tipo} toggle />}
                {closeCash&&
                <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>$</InputGroupText>
                </InputGroupAddon> 
                <Input type="number" pattern="[0-9]*" style={{fontSize:"26px",fontWeight:800}}  value={saldo[x.tipo]}  onChange={(e)=>setSaldo({...saldo,[x.tipo]:e.target.value})}/>
              </InputGroup>}
              </Col>
            </Fragment>
            )}
          {printer?.isConnected && isRetiro &&<Col xs={12}>
            <Header as='h3' style={{marginBottom:"5px",marginTop:'10px'}}>Imprimir retiro</Header>
            <Radio onChange={()=>setPrint(!print)} checked={print} toggle />
            </Col>}
          <Col xs={12}></Col>
              {/*<Col xs={12} md={2}> 
                <h3>Tipo</h3><Input value={tipo} onChange={(e)=>{setTipo(e.target.value)}}
                type="select">{tipos.map((x,i)=>(<option key={i}>{x}</option>))}</Input>
              </Col>*/}
            {!closeCash&&<Col xs={12} md={8} > 
                <h3>Concepto</h3>
                <Input type="textarea" value={descripcion} onChange={(e)=>{setDescripcion(e.target.value)}}/>
            </Col>}
            <br></br>
            {<Col xs={12} md={4} > 
                <h3>{closeCash?isCashClose?'Efectivo dia siguiente':"Efectivo inicial dia siguiente":'Valor'}</h3>                    
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>$</InputGroupText>
                    </InputGroupAddon> 
                    <Input type="number" pattern="[0-9]*" style={{fontSize:"26px",fontWeight:800}} value={precio} onChange={(e)=>{
                    setPrecio(e.target.value)}
                    }/>
                </InputGroup>
            </Col>}
            {closeCash&&<Col xs={12} md={12} > 
                <h3>Tu nombre</h3>
                <Input  type="textarea" value={descripcion} onChange={(e)=>{setDescripcion(e.target.value)}}/>
            </Col>}
          </Row>
          </ModalBody>
          <ModalFooter>
            <Button disabled={cashMovementLoading} id={"buttonTest"} style={{float:"right"}} color="grey" size={"lg"} onClick={()=>{setOpen(false)}}>Cancelar</Button>
            <Button disabled={cashMovementLoading} id={"buttonTest"} style={{float:"right"}} color={closeCash?"warning":isRetiro?'info':'success'} size={"lg"} onClick={
               ()=>{
                   if (!closeCash&&(precio==undefined||precio<1)) {
                       alert('El monto no puede ser menor a 1')
                   }
                   else if(descripcion==undefined||descripcion.trim()==""){
                    alert('El concepto no puede estar vacio')
                   }
                   else if(tipo==""){
                    alert('Falta elegir el tipo de pago')
                   }
                   else if(!isCashClose&&closeCash&&tipoPagos.find(x=>saldo[x.tipo]==undefined)){
                    alert('Todos los valores deben estar cargados para cerrar la caja')
                   }
                   else{
                    dispatch({type:'ADD_CASH_MOVEMENT',payload:{actorName:descripcion,type:tipo,saldo,cashName:'abajo',amount:precio,isWithdrawal:openCash?false:isRetiro,closeCash,
                    concept:descripcion,
                    onComplete:()=>{
                      if(printer?.isConnected && isRetiro && print){
                        printCashWithdraw({amount:precio,concept:descripcion,withdrawType:tipo})
                      }
                      initAll();}}})
                   }
                
                }
            }>Confirmar</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
   
    )
}



export default ModalCaja

